import { useSelector } from "react-redux";
import { selectCurrency, selectUserInfo } from "../app/appSlice";
const showKeys = [
  { label: "coupon", key: "coupon_fee", sign: "-" },
  { label: "discount", key: "discount", sign: "-" },
  { label: "pack", key: "pack_fee", sign: "+" },
  { label: "insurance", key: "insurance_fee", sign: "+" },
  { label: "delivery", key: "delivery_fee", sign: "+" },
  { label: "assembly", key: "assembly_fee", sign: "+" },
  { label: "freight", key: "fare_fee", sign: "+" },
];

export const getPrice = (price) => {
  return Math.ceil(price.toFixed(2));
};


export const useDiscountPrice = () => {
  const userInfo = useSelector(selectUserInfo);
  const getDiscount = (productTotalPrice) => {
    let matchDiscount = 0;
    let arr = userInfo.buy_discount ? [...userInfo.buy_discount] : [];
    arr
      .sort((a, b) => a[0] - b[0])
      .forEach((item) => {
        if (productTotalPrice - item[0] >= 0) {
          matchDiscount = item[1] / 100;
        }
      });
    if (matchDiscount) matchDiscount = getPrice(matchDiscount * productTotalPrice);
    return matchDiscount;
  };
  return { getDiscount };
};

export const useCalcOrderPrice = () => {
  const { getDiscount } = useDiscountPrice();
  const getExtra = (orderInfo) => {
    // console.log('支付模块的数据',orderInfo)
    let discount = 0;
    let price = -(orderInfo.coupon_fee || 0) - discount;
    price = price + orderInfo.pack_fee + orderInfo.insurance_fee + orderInfo.delivery_fee + orderInfo.assembly_fee;
    if (orderInfo.extraPrice) {
      price = Object.values(orderInfo.extraPrice).reduce((a, b) => a + b || 0, price);
    } else {
      price = price + orderInfo.fare_fee || 0;
    }
    return price;
  };
  const calcOrderPrice = (orderInfo, config = { calcExtra: true }) => {
    let price = 0;
    if (!orderInfo) return 0;
    if (!orderInfo.status && orderInfo.status !== 0) {
      price = 0;
    } else if (orderInfo.status === 2) {
      if (config.isDeposit) price = orderInfo.goods_fee * 0.2;
      else {
        let balance = orderInfo.goods_fee - orderInfo.paid_fee;
        if (!config.calcExtra) {
          price = balance;
        } else {
          price = balance + getExtra(orderInfo);
        }
      }
    } else {
      price = orderInfo.goods_fee + getExtra(orderInfo);
    }
    return getPrice(price);
  };
  return { calcOrderPrice };
};

export const useOrderPriceList = () => {
  const currency = useSelector(selectCurrency);
  const { getDiscount } = useDiscountPrice();
  const handleExtra = (orderInfo, list) => {
    showKeys.forEach((item) => {
      if (item.key === "discount") {
        let discount = 0;
        list.push({ label: `paymentText.${item.label}`, price: discount, sign: "-" });
      } else if (item.key === "fare_fee") {
        if (orderInfo.fare_fee > 0 && orderInfo.paid_fee >= orderInfo.order_fee) {
          list.push({ label: `paymentText.${item.label}`, price: orderInfo[item.key], sign: item.sign });
        }
      } else if (orderInfo[item.key] > 0) {
        list.push({ label: `paymentText.${item.label}`, price: orderInfo[item.key], sign: item.sign });
      }
    });
    return list;
  };

  const handleExtraPrice = (orderInfo, list) => {
    // console.log('orderInfo', orderInfo)
    if (!orderInfo.extraPrice) return list;
    Object.keys(orderInfo.extraPrice).forEach((key) => {
      let value = orderInfo.extraPrice[key];
      if (value > 0) {
        list.push({ label: `paymentText.${key}`, price: value, sign: "+" });
      }
    });
    return list;
  };

  const getPriceList = (orderInfo, config = {}) => {
    let basicList = [{ label: "paymentText.totalItems", price: orderInfo.goods_fee, sign: "+" }];
    if (orderInfo.status === 2) {
      if (config.isDeposit) {
        basicList.push({
          type: "text",
          label: "paymentText.deposit",
          text: `${currency} ${orderInfo.goods_fee / 100}X20%`,
        });
      } else {
        basicList = handleExtra(orderInfo, basicList);
      }
    } else if (orderInfo.logistics_mode !== "fob") {
      basicList = handleExtra(orderInfo, basicList);
    }
    if (config.extraPrice === true) {
      basicList = handleExtraPrice(orderInfo, basicList);
    }
    return basicList;
  };
  return { getPriceList };
};

export const useCartPriceObj = () => {
  const { getDiscount } = useDiscountPrice();
  const getPriceObj = (list = []) => {
   
    let total = 0;
    let packing_fee = 0;
    let insurance_fee = 0;
    let delivery_fee = 0;
    let assembly_fee = 0;
    // 特价区价格
    let originalPrice = 0
    let totalNumber = 0
    let favorableTotalPrice = 0
    list.forEach((item) => {
    
      if (item.priceType == 'fob_price') {
        // 立即购买fob价格处理
        let showPrice = (item.special_offer > 0 ? item.special_offer : item.fob_price) || 0;
        let totalPrice = showPrice * item.amount;
        total += totalPrice;
        packing_fee += (item.packing_fee || 0) * item.amount;
        insurance_fee += (item.insurance_fee || 0) * item.amount;
        delivery_fee += (item.delivery_fee || 0) * item.amount;
        assembly_fee += (item.assembly_fee || 0) * item.amount;
      } else if (item.logistics_mode == "fob") {
        // 购物车 列表fob数据
        let showPrice = (item.special_offer > 0 ? item.special_offer : item.fob_price) || 0;
        let totalPrice = showPrice * item.amount;
        total += totalPrice;
        packing_fee += (item.packing_fee || 0) * item.amount;
        insurance_fee += (item.insurance_fee || 0) * item.amount;
        delivery_fee += (item.delivery_fee || 0) * item.amount;
        assembly_fee += (item.assembly_fee || 0) * item.amount;
      }else if (item.priceType == "special_price") {
        // 特价区
        let showPrice = item.special_price|| 0;
        let totalPrice = showPrice * item.amount;
        total += totalPrice;
        // 原价
        let o_prcie = item.price;
        let itemOriginPrice = 0
        itemOriginPrice= o_prcie * Number(item.amount);
        originalPrice+=itemOriginPrice
        // 原价数量
        totalNumber+=Number(item.amount);
        // 优惠总价格
        favorableTotalPrice=Number(originalPrice) - Number(total)
      }else if (item.logistics_mode == "special") {
        // console.log('选择的',item)
        // 特价区购物车数据
        let showPrice = item.price|| 0;
        let totalPrice = showPrice * item.amount;
        total += totalPrice;
      } else {
        // 原始价格 美仓购物车
        let showPrice = (item.special_offer > 0 ? item.special_offer : item.price) || 0;
        let totalPrice = showPrice * item.amount;
        total += totalPrice;
        packing_fee += (item.packing_fee || 0) * item.amount;
        insurance_fee += (item.insurance_fee || 0) * item.amount;
        delivery_fee += (item.delivery_fee || 0) * item.amount;
        assembly_fee += (item.assembly_fee || 0) * item.amount;
      }


      // let showPrice = (item.special_offer > 0 ? item.special_offer : item.price) || 0;
      // let totalPrice = showPrice * item.amount;
      // total += totalPrice;
      // packing_fee += (item.packing_fee || 0) * item.amount;
      // insurance_fee += (item.insurance_fee || 0) * item.amount;
      // delivery_fee += (item.delivery_fee || 0) * item.amount;
      // assembly_fee += (item.assembly_fee || 0) * item.amount;
    });
    // 超过了3000美元  就优惠价格，暂时不要，写死
    // const discount = getDiscount(total);
    const discount = 0;
    total = getPrice(total);
    packing_fee = getPrice(packing_fee);
    insurance_fee = getPrice(insurance_fee);
    delivery_fee = getPrice(delivery_fee);
    assembly_fee = getPrice(assembly_fee);
    // 特价区返回数据
    originalPrice = getPrice(originalPrice)
    totalNumber = getPrice(totalNumber)
    favorableTotalPrice = getPrice(favorableTotalPrice)
    return { total, discount, packing_fee, insurance_fee, delivery_fee, assembly_fee,originalPrice,totalNumber,favorableTotalPrice};
  };
  return { getPriceObj };
};

export const useCalcCartPrice = () => {
  const calcCartPrice = (priceObj, config = {}) => {
    const { total, packing_fee, insurance_fee, delivery_fee, assembly_fee, discount } = priceObj;
    const { activeCoupon, is_packing, is_insurance, is_assembly, is_delivery } = config;
    let price = total - (activeCoupon ? activeCoupon.par_value : 0) - discount;
    price =
      price +
      (is_packing ? packing_fee : 0) +
      (is_insurance ? insurance_fee : 0) +
      (is_assembly ? delivery_fee : 0) +
      (is_delivery ? assembly_fee : 0);
    return getPrice(price);
  };
  return { calcCartPrice };
};
