import React from "react";
// import loadable from "@loadable/component";
import DefaultLayout from "../components/Layout/DefaultLayout";
export const routesData = [
  // invoice
  { path: "print", component: React.lazy(() => import("../pages/Print")) },
  //合并打印
  { path: "contractPrint", component: React.lazy(() => import("../pages/BolContractPrint")) },
  // bol
  { path: "print_bol", component: React.lazy(() => import("../pages/PrintBol")) },

  // 线下支付 --->全额发票
  { path: "print_offline", component: React.lazy(() => import("../pages/PrintOFFline")) },
  // 线下支付 --->提货单
  { path: "print_bol_offline", component: React.lazy(() => import("../pages/PrintBolOFFline")) },
  // 线下支付 --->预售单发票列表
  { path: "print_advance_order", component: React.lazy(() => import("../pages/PrintAdvanceOrder")) },
  //  预售单BOL

  { path: "print_advance_bol", component: React.lazy(() => import("../pages/PrintAdvanceBol")) },
  // 预售单发票详情
  { path: "print_advance_info", component: React.lazy(() => import("../pages/PrintOFFlineInfo")) },
  // fob新版发票列表
  { path: "print_fob_list", component: React.lazy(() => import("../pages/PrintFobList")) },
  // 预售单发票详情
  { path: "print_fob_info", component: React.lazy(() => import("../pages/PrintFobInfo")) },

  // 订购合同列表
  { path: "contract_purchase", component: React.lazy(() => import("../pages/ContractPurchase")) },
  { path: "contract_print", component: React.lazy(() => import("../pages/ContractPrint")) },

  {
    path: "*",
    component: DefaultLayout,
    children: [
      { path: "new_arrival", component: React.lazy(() => import("../pages/NewArrival")) },
      { path: "hot_sale", component: React.lazy(() => import("../pages/HotSale")) },
      // 特价区
      { path: "special_sale", component: React.lazy(() => import("../pages/SpecialSale")) },
      { path: "fob_zone", component: React.lazy(() => import("../pages/FobZone")) },
      // { path: "manufacturer", component: React.lazy(() => import("../pages/Manufacturer")) },
      { path: "store_list", component: React.lazy(() => import("../pages/StoreList")) },

      // 新增预售专区
      { path: "presale_area", component: React.lazy(() => import("../pages/PresaleArea")) },
      // 新增海上库存展示表
      { path: "inventory", component: React.lazy(() => import("../pages/Inventory")) },
      // 新增我的库存展示表
      { path: "my_inventory", component: React.lazy(() => import("../pages/MyInventory")) },

      { path: "store", component: React.lazy(() => import("../pages/Store")) },
      { path: "category", component: React.lazy(() => import("../pages/Category")) },
      { path: "product", component: React.lazy(() => import("../pages/Product")) },
      { path: "cart", component: React.lazy(() => import("../pages/Cart")), meta: { checkLogin: true } },
      { path: "buyNow", component: React.lazy(() => import("../pages/Cart/BuyNow")), meta: { checkLogin: true } },
      { path: "fobBuyNow", component: React.lazy(() => import("../pages/Cart/FobBuyNow")), meta: { checkLogin: true } },
      { path: "specialBuyNow", component: React.lazy(() => import("../pages/Cart/SpecialBuyNow")), meta: { checkLogin: true } },
      {
        path: "international_logistics", component: React.lazy(() => import("../pages/InternationalLogistics"))
      },
      // 华美银行支付页面
      {
        path: "pay/convergePay",
        component: React.lazy(() => import("../components/Introduce")),
        meta: { checkLogin: true },
      },
      // 支付成功信息页面
      { path: "success", component: React.lazy(() => import("../components/Success")) },
      {
        path: "reservations",
        component: React.lazy(() => import("../pages/Cart/Reservations")),
        meta: { checkLogin: true },
      },
      {
        path: "reservations/final",
        component: React.lazy(() => import("../pages/Cart/ReservationsFinal")),
        meta: { checkLogin: true },
      },
      {
        path: "reservations/pay",
        component: React.lazy(() => import("../pages/Pay/ReservationsPay")),
        meta: { checkLogin: true },
      },
      {
        path: "reservations/payResult",
        component: React.lazy(() => import("../pages/Pay/ReservationsPay/Result")),
        meta: { checkLogin: true },
      },
      { path: "login", component: React.lazy(() => import("../pages/Login")) },
      { path: "register", component: React.lazy(() => import("../pages/Register")) },
      // 忘记密码
      { path: "forgetPassword", component: React.lazy(() => import("../pages/forgetPwd")) },
      // 中国注册
      { path: "registerChinaUser", component: React.lazy(() => import("../pages/ChinaRegister")) },
      // 关联成功页面
      { path: "correlationSuccess", component: React.lazy(() => import("../pages/correlationSuccess")) },
      // 注册成功页面
      { path: "registerSuccess", component: React.lazy(() => import("../pages/RegisterSuccess")) },
      { path: "merchantSettlement", component: React.lazy(() => import("../pages/MerchantSettlement")) },
      { path: "merchantSuccess", component: React.lazy(() => import("../pages/MerchanSuccess")) },


      { path: "pay", component: React.lazy(() => import("../pages/Pay")), meta: { checkLogin: true } },
      {
        path: "pay/payResult",
        component: React.lazy(() => import("../pages/Pay/PayResult")),
        meta: { checkLogin: true },
      },
      { path: "help_center", component: React.lazy(() => import("../pages/HelpCenter")) },
      {
        path: "user_center",
        meta: { checkLogin: true },
        component: React.lazy(() => import("../pages/UserCenter/Layout")),
        children: [
          { path: "home_page", component: React.lazy(() => import("../pages/UserCenter/HomePage")) },
          {
            path: "essential_information",
            component: React.lazy(() => import("../pages/UserCenter/EssentialInformation")),
          },
          { path: "my_collection", component: React.lazy(() => import("../pages/UserCenter/MyCollection")) },
          { path: "order", component: React.lazy(() => import("../pages/UserCenter/Order")) },
          // // 订购合同列表
          // { path: "contract_purchase", component: React.lazy(() => import("../pages/UserCenter/ContractPurchase")) },

          { path: "after_sale_services", component: React.lazy(() => import("../pages/UserCenter/AfterSaleServices")) },
          { path: "message_center", component: React.lazy(() => import("../pages/UserCenter/MessageCenter")) },
          { path: "feedback", component: React.lazy(() => import("../pages/UserCenter/Feedback")) },
          // 站内信入口
          { path: "stationMsg", component: React.lazy(() => import("../pages/UserCenter/FtationMsg")) },
          { path: "balance", component: React.lazy(() => import("../pages/UserCenter/AccountBalance")) },
          // { path: "procurement_records", component:React.lazy(() => import("../pages/UserCenter/EssentialInformation")) },
          { path: "coupon", component: React.lazy(() => import("../pages/UserCenter/Coupon")) },
          { path: "setting", component: React.lazy(() => import("../pages/UserCenter/Setting")) },
          // 用户认证
          { path: "certification", component: React.lazy(() => import("../pages/UserCenter/Certification")) },
          // 中国用户认证
          { path: "certificationChina", component: React.lazy(() => import("../pages/UserCenter/CertificationChina")) },

        ],
      },
      { path: "home", component: React.lazy(() => import("../pages/Home")) },
      { path: "*", replace: "/home" },
    ],
  },
];
