/**
 * @description 通过调用erpApi.js 中转请求ERP数据
 */

import { fetchErp } from './erpApi'

/**
 * @description 统一处理商品获取库存详情数据改工具方法适用多个数据
 * @param {Array} productRes 商品列表
 * @author Ahao
 * @date 2023年7月7日
*/
async function fetchProductData(productRes) {
  try {
    //判断数据的有效性
    if (!Array.isArray(productRes) || productRes.length === 0) {
      return []
    }
    let filterGoodsSn = productRes.map(item => item['goods_sn']).join(',')
    let queryParams = {
        request_address: '/market/getGoodsStock',
        method: 'get',
        param: JSON.stringify({ goods_sn: filterGoodsSn })
    }
    const productErpRes = await fetchErp(queryParams)
        //将数据传递对应goods_sn
    const newProductAry = productRes.map(item => {
    let goodsObj = productErpRes[item.goods_sn]
    return item['goods_sn'] === goodsObj.goods_sn
              ? {
                ...item, 
                public_stock:goodsObj.public_stock, 
                pending_stock: goodsObj.pending_stock
              } 
              : null
      })
    return newProductAry
  } catch (error) {
    console.error('商品库存获取发生错误', error)
  }
}

export {
  fetchProductData
}