import { InboxOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useFormate } from "../../hooks/useFormate";
const Empty = ({ text, sx }) => {
  const { formatMsg } = useFormate();
  return (
    <Box sx={{ color: "rgba(0,0,0,0.4)", textAlign: "center", minHeight: 200, pt: 10, ...sx }}>
      <InboxOutlined sx={{ width: 50, height: 50 }}></InboxOutlined>
      <Typography variant="body2" sx={{ mt: 1 }}>
        {formatMsg({ id: text || "noData" })}
      </Typography>
    </Box>
  );
};
export default Empty;
