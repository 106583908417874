import request from "../../utils/request";

/**
 * @description 获取地址簿列兵
 * @param {Object} data 
 * @returns 
 */
export const getAddressList = (data) => {
  return request.post("/supply/order/fob/getPurchaseContractUserAddressList", data);
}

/**
 * @description 保存地址到地址簿
 * @param {Object} data 
 * @returns 
 */
export const saveAddress = (data) => {
  return request.post("/supply/order/fob/savePurchaseContractUserAddress", data)
}