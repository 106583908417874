import request from "../../utils/request";

export const fetchProducts = (data = {}) => {
  let params = { ...data };
  try {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("search")) {
      params.search = urlParams.get("search");
    }
  } catch (error) {}
  if (params.warehouse_ids) params.warehouse_ids = params.warehouse_ids.join(",");
  if (params.mch_ids) params.mch_ids = params.mch_ids.join(",");
  // if (params.is_fob === "2") delete params.is_fob;
  //  散件专区请求
  return request.post("/supply/goods/goods/list", params).then(({ list = [], count = 0 }) => ({
    list,
    count,
  }));
};

export const fetchZoneList = (data = {}) => {
  // goods_sn  不知道传什么what?
  return request.post("/supply/goods/warehouse/list",{goods_sn:'what?'}).then(({ list }) => {
    list = list || [];
    return list.map((item) => ({ id: item.warehouse_id, name: item.name }));
  });
  // return []
};
export const fetchFactoryList = (data = {}) => {
  return request.post("/supply/goods/factory/list").then(({ list }) => list || []);
};
