import { Box, Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectMenuList } from "../../app/appSlice";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ListItem = (props) => {
  const hasChildren = props.info.children && props.info.children.length > 0;
  const open = props.openKeys.find((o) => o === props.info.type_id) >= 0;
  return (
    <React.Fragment>
      <ListItemButton
        onClick={() => props.onClick(props.info.type_id)}
        sx={{ ml: props.info.image_pc ? "10px" : "34px" }}
      >
        {props.info.image_pc && (
          <img style={{ marginRight: 12 }} alt={""} src={props.info.image_pc} width={24} height={24}></img>
        )}
        <ListItemText>{props.info.title}</ListItemText>
        {hasChildren && (
          <Box
            onClick={(e) => {
              e.stopPropagation();
              props.toggleOpen(props.info.type_id);
            }}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </Box>
        )}
      </ListItemButton>
      {hasChildren && (
        <Collapse in={open} sx={{ ml: 1.5, color: "#555",  ".MuiTypography-root": { fontSize: "14px" } }}>
          <List component="div">
            {props.info.children.map((childrenItem, index) => (
              <ListItem
                key={index}
                onClick={props.onClick}
                info={childrenItem}
                openKeys={props.openKeys}
                toggleOpen={props.toggleOpen}
              ></ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
};

const SiderMenu = ({ onClose }) => {
  const menuList = useSelector(selectMenuList);
  const [openKeys, setOpenKeys] = useState([]);
  const navigate = useNavigate();
  const handleClick = (id) => {
    navigate("/category?category_id=" + id);
    onClose();
  };
  const toggleOpen = (id) => {
    let index = openKeys.findIndex((o) => o === id);
    if (index >= 0) {
      openKeys.splice(index, 1);
    } else {
      openKeys.push(id);
    }
    setOpenKeys([...openKeys]);
  };
  return (
    <List>
      {menuList.map((item, i) => (
        <ListItem
          key={item.type_id}
          info={item}
          onClick={handleClick}
          openKeys={openKeys}
          toggleOpen={toggleOpen}
        ></ListItem>
      ))}
    </List>
  );
};

export default SiderMenu;
