import request from "../../utils/request";
export const fetchOrderInfo = (data) => {
  return request.post("/supply/order/order/info", data);
};

export const toBalancePay = (data) => {
  return request.post("/supply/order/pay/balance", data);
};

export const getRegion = (data) => {
  return request.post("/system/common/region/list", data);
};

export const paypalPay = (data) => {
  return request.post("/supply/order/pay/paypal", data);
};

export const stripePay = (data) => {
  return request.post("/supply/order/pay/stripe", data);
};


export const saveAddress = (data) => {
  return request.post("/supply/order/order/address", data);
};

// 获取跳转物流的地址
export const getLogisticsPath = (data) => {
  return request.post("/supply/order/logistics/query_link", data);
};

export const getLogisticsPrice = (data) => {
  return request.post("/supply/order/logistics/query_price", data);
};

// 调取华美支付接口
export const convergePay = (data) => {
  return request.post("/supply/order/pay/converge", data);
};

// 调取线下支付接口
export const offlinePaymentPay = (data) => {
  return request.post("/supply/order/pay/offline", data);
};

// 获取第三方下拉数据
export const thirdDataList = (data) => {
  return request.post("/supply/order/third_party/list", data);
};

// 再来一单列表数据
export const againOrderList = (data) => {
  return request.post("/supply/order/AgainOrder/info", data);
};