import React from "react";
import {
  Box,
  Grid,
  Stack,
  IconButton,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
  Badge,
  Container,
  Link,
  SwipeableDrawer,
  Divider,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import {
  MailOutline,
  Search,
  ShoppingCartOutlined,
  AccountCircleOutlined,
  LocalFireDepartment,
  MenuOutlined,
} from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { selectIsLogin, selectLogo, setMessageInfo, setLogoutModal } from "../../app/appSlice";
import { useEffect, useRef, useState } from "react";
import { fetchNewMessage } from "./api";
import ImgBox from "../ImgBox";
import { getCartListAsync, selectCartProductNum } from "../../pages/Cart/cartSlice";
import useBreakPoint from "../../hooks/useBreakPoint";
import ButtonMenu from "../Menu/ButtonMenu";
import { useFormate } from "../../hooks/useFormate";
import SiderMenu from "../Menu/SiderMenu";
const menuList = [
  { path: "/home", name: "home" },
  { path: "/fob_zone", name: "fobZone" },
  { path: "/new_arrival", name: "newArrival" },
  { path: "/hot_sale", name: "hotSale" },
  // { path: "/store_list", name: "store" },
  // 海运库存展示表
  { path: "/inventory", name: "inventory" },
  // 特价区
  { path: "/special_sale", name: "special" },
  // 我都库存
  { path: "/my_inventory", name: "myInventory" },
];
const siderButtons = [
  { path: "/user_center", name: "userCenter", loginStatus: true },
  { path: "/logout", name: "logout", loginStatus: true },
  { path: "/login", name: "login", loginStatus: false },
  { path: "/register", name: "register", loginStatus: false },
  { path: "/help_center", name: "header.helpCenter" },
  { path: "/help_center?pageKey=contact_us", name: "header.contactUs" },
];
const MenuBar = () => {
  const { bPointesChcek, breakpoints } = useBreakPoint();
  const [siderMenu, setSiderMenu] = useState(false);
  const dispatch = useDispatch();
  const { formatMsg } = useFormate();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isLogin = useSelector(selectIsLogin);
  const logo = useSelector(selectLogo);
  const cartProductNum = useSelector(selectCartProductNum);
  const [messageNum, setMessageNum] = useState(0);
  const inputRef = useRef();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const handleNavigate = (path) => {
    if (path === "/logout") {
      dispatch(setLogoutModal(true));
    } else {
      navigate(path);
    }
    setSiderMenu(false);
  };
  const handleNav = (path) => {
    if (!isLogin) {
      navigate("/login");
    } else {
      navigate(path);
    }
  };
  // 跳转到工厂列表模块
  const handleNavigator = () => {
    navigate("/store_list");
  };
  const toHome = () => {
    navigate("/home");
  };
  const handleSearch = () => {
    let value = inputRef.current.value;
    if (!value) {
      dispatch(setMessageInfo(formatMsg("searchbar.inputEmpty")));
      return;
    }
    navigate(`/category?search=${value}`);
  };
  const handleKeyDown = (e) => {
    let key = e.keyCode || e.which;
    if (key === 13) {
      handleSearch();
    }
  };
  useEffect(() => {
    if (isLogin) {
      dispatch(getCartListAsync({ logistics_mode: "self" }));
    }
  }, [isLogin]);
  useEffect(() => {
    if (isLogin) {
      fetchNewMessage().then((data) => {
        setMessageNum(data.count);
      });
    }
    if (inputRef.current) {
      inputRef.current.value = searchParams?.get("search") || "";
    }
  }, [location, isLogin]);
  useEffect(() => {
    if (!breakpoints.lg && !breakpoints.xl) {
      setSiderMenu(false);
    }
  }, [breakpoints]);
  return (
    <React.Fragment>
      {bPointesChcek(["lg", "xl"]) && (
        <React.Fragment>
          <Box className="component-search-bar">
            <Container>
              <Grid container justifyContent="space-between" alignItems="center" columnSpacing={2} sx={{ height: 100 }}>
                <Grid item xs="auto">
                  <ImgBox disabledScale width={180} height={95} src={logo} onClick={toHome}></ImgBox>
                </Grid>
                <Grid item xs>
                  <Box>
                    <FormControl fullWidth>
                      <OutlinedInput
                        size="small"
                        inputRef={inputRef}
                        onKeyDown={handleKeyDown}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleSearch} aria-label="search">
                              <Search></Search>
                            </IconButton>
                          </InputAdornment>
                        }
                      ></OutlinedInput>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item>
                  <Stack direction={"row"}>
                    <Box
                      onClick={() => handleNav("/user_center")}
                      sx={{ p: 2, cursor: "pointer", display: "flex", flexDirection: "column", alignItems: "center" }}
                    >
                      <AccountCircleOutlined></AccountCircleOutlined>
                      <Typography color="#5e5e5e" component={"div"}>
                        {formatMsg("searchbar.btn.user")}
                      </Typography>
                    </Box>
                    <Box
                      onClick={() => handleNav("/user_center/message_center")}
                      sx={{ p: 2, cursor: "pointer", display: "flex", flexDirection: "column", alignItems: "center" }}
                    >
                      {isLogin && (
                        <Badge color="tagBlack" badgeContent={messageNum}>
                          <MailOutline></MailOutline>
                        </Badge>
                      )}
                      {!isLogin && <MailOutline></MailOutline>}
                      <Typography color="#5e5e5e" component={"div"}>
                        {formatMsg("searchbar.btn.message")}
                      </Typography>
                    </Box>
                    <Box
                      onClick={() => handleNav("/cart")}
                      sx={{ p: 2, cursor: "pointer", display: "flex", flexDirection: "column", alignItems: "center" }}
                    >
                      {isLogin && (
                        <Badge color="tagBlack" badgeContent={cartProductNum}>
                          <ShoppingCartOutlined />
                        </Badge>
                      )}
                      {!isLogin && <ShoppingCartOutlined />}
                      <Typography color="#5e5e5e" component={"div"}>
                        {formatMsg("searchbar.btn.cart")}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box
            className="components-menu-bar"
            sx={{
              height: 48,
              borderBottom: "1px solid #dcdddc",
              borderTop: "1px solid #dcdddc",
              ".component-button-menu": {
                height: "100%",
                button: {
                  height: "100%",
                  background: "#f4f4f4",
                  color: "#333",
                  boxShadow: "none",
                },
              },
            }}
          >
            <Container sx={{ height: "100%" }}>
              <Box className="menu-bar" sx={{ display: "flex", height: "100%", alignItems: "center", position: "relative" }}>
                {/* 菜单按钮  首页 ALL CATEGORIES */}
                <ButtonMenu></ButtonMenu>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  flexWrap={"wrap"}
                  sx={{ height: "100%", padding: "0 20px" }}
                >
                  {/* width>1475显示的 */}
                  {menuList.map((item) => (
                    <Box
                      key={item.path}
                      className="link-item"
                      onClick={() => handleNavigate(item.path)}
                      sx={{
                        height: "100%",
                        lineHeight: "48px",
                        px: 1.5,
                        cursor: "pointer",
                        a: {
                          padding: "6px 0",
                        },
                        ":hover": {
                          a: {
                            color: "#FFC10B",
                            borderBottom: "2px solid #FFC10B"
                          },
                        },
                      }}
                    >
                      <Link underline={"none"} color={pathname === item.path ? "#FFC10B" : "#7b7b7b"}

                        borderBottom={pathname === item.path ? "2px solid #FFC10B" : "none"}
                      >
                        {item.path === "/fob_zone" && (
                          <LocalFireDepartment
                            size="small"
                            sx={{ verticalAlign: "middle", color: "#ffc310" }}
                          ></LocalFireDepartment>
                        )}
                        {formatMsg({ id: `menubar.${item.name}` })}
                      </Link>
                    </Box>
                  ))}
                </Stack>
                <Box sx={{
                  position: "absolute", right: 0,
                  cursor: "pointer",
                  fontSize: "14px",
                  color: "#fff",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  display: "flex",
                }}
                  backgroundColor={pathname === '/store_list' ? "#FFC10B" : "#1ACC72"}
                  onClick={() => handleNavigator()}
                >
                 {/* <Box  sx={{textAlign:"center",}}>{formatMsg("menubar.store")}</Box>*/}
                 {/*<Box sx={{textAlign:"center",}}>{formatMsg("menubar.purchasing")}</Box>*/}
                  <Box sx={{textAlign:"center",}}>{formatMsg("product.us.fobPrice")}</Box>
                  {/* <Box sx={{ textAlign: "center", }}>{formatMsg("menubar.purchaser")}</Box> */}
                </Box>
              </Box>
            </Container>
          </Box>
        </React.Fragment>
      )}
      {bPointesChcek(["sm", "xs", "md"]) && (
        <Box sx={{ borderBottom: "1px solid #efefef" }}>
          <Grid container alignItems={"center"} pl={1} pr={1} sx={{ height: 60 }}>
            <Grid item xs="auto">
              <IconButton onClick={() => setSiderMenu(true)}>
                <MenuOutlined></MenuOutlined>
              </IconButton>
            </Grid>
            <Grid item flex={1}>
              <ImgBox disabledScale width={180} height={48} src={logo} onClick={toHome}></ImgBox>
            </Grid>
            <Grid item xs="auto">
              <Stack spacing={2}>
                <AccountCircleOutlined onClick={() => handleNav("/user_center")}></AccountCircleOutlined>
                <ShoppingCartOutlined onClick={() => handleNav("/cart")} />
              </Stack>
            </Grid>
          </Grid>
          <SwipeableDrawer
            anchor="left"
            open={siderMenu}
            onOpen={() => setSiderMenu(false)}
            onClose={() => setSiderMenu(false)}
          >
            <Box sx={{ width: 350, pb: 2 }}>
              <Grid container wrap="wrap" sx={{ width: "100%" }}>
                {menuList.map((item) => (
                  <Grid
                    item
                    xs={6}
                    key={item.path}
                    className="link-item"
                    onClick={() => handleNavigate(item.path)}
                    sx={{
                      lineHeight: "48px",
                      px: 1.5,
                      cursor: "pointer",
                      ":hover": {
                        a: {
                          color: "#002ea6",
                        },
                      },
                    }}
                  >
                    <Link underline={"none"} color={pathname === item.path ? "#002ea6" : "#7b7b7b"}>
                      {item.path === "/fob_zone" && (
                        <LocalFireDepartment
                          size="small"
                          sx={{ verticalAlign: "middle", color: "#ffc310" }}
                        ></LocalFireDepartment>
                      )}
                      {formatMsg({ id: `menubar.${item.name}` })}
                    </Link>

                  </Grid>

                ))}
                 <Box sx={{
                  cursor:"pointer",
                  display:"flex",
                  alignItems:"center",
                  padding:"0 12px",
                  ":hover":{
                    color:"#002ea6",
                  }
                }}
                  color={pathname === '/store_list' ? "#002ea6" : "#7b7b7b"}
                  onClick={() => handleNavigator()}
                >
                 {formatMsg("menubar.purchaseList")}
                </Box>
              </Grid>
              <Divider></Divider>
              <Box sx={{ px: 2, py: 1 }}>
                <FormControl fullWidth>
                  <OutlinedInput
                    size="small"
                    inputRef={inputRef}
                    onKeyDown={handleKeyDown}
                    placeholder={formatMsg("searchbar.inputEmpty")}
                    sx={{
                      "input::placeholder": {
                        fontSize: 12,
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={handleSearch} aria-label="search">
                          <Search></Search>
                        </IconButton>
                      </InputAdornment>
                    }
                  ></OutlinedInput>
                </FormControl>
              </Box>
              <SiderMenu onClose={() => setSiderMenu(false)} />
              <Divider></Divider>
              <List>
                {siderButtons.map((item, index) => {
                  if (item.loginStatus === false && isLogin) return null;
                  if (item.loginStatus === true && !isLogin) return null;
                  return (
                    <ListItemButton key={index} onClick={() => handleNavigate(item.path)}>
                      <ListItemText> {formatMsg(item.name)}</ListItemText>
                    </ListItemButton>
                  );
                })}
              </List>
            </Box>
          </SwipeableDrawer>
        </Box>
      )}
    </React.Fragment>
  );
};

export default MenuBar;
