import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchProducts, fetchZoneList } from "./api";
import { fetchProductData } from '../../network/productErpApi'
const initialState = {
  params: {
    is_component: "3",
    first_available: "0",
    is_fob: "0",
    sort: "d",
    sort_type: "newest",
    small_sort: { name: "sort.newArrival", id: "newest=>d" },
  },
  loading: false,
  listData: [],
  total: 0,
  selectIds: [],
  zoneList: [],
};

export const fetchListAsnyc = createAsyncThunk("category/fetchList", async (data, { getState }) => {
  const { params } = getState().category;
  const isLogined = !!getState().app.token //判断是否登录
  let fetchProductRes = await  fetchProducts({ ...params, ...data })
  if (!isLogined || params.is_fob === '1') { //没有登录或者是选择显示fob数据也直接不请求对应库存
    return fetchProductRes
  }
  let productStockAry = await fetchProductData(fetchProductRes.list)
  return Object.assign({}, fetchProductRes, { list: productStockAry })
});
export const fetchZoneListAsync = createAsyncThunk("category/fetchZoneList", async () => {
  return await fetchZoneList();
});
export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setParams: (state, { payload }) => {
      state.params = { ...state.params, ...payload };
    },
    setSelects: (state, { payload }) => {
      state.selectIds = payload;
    },
    resetCategory: (state) => {
      state.params = { ...initialState.params };
      state.selectIds = [];
      state.listData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListAsnyc.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchListAsnyc.rejected, (state) => {
        state.loading = false;
        state.total = 0;
        state.listData = [];
      })
      .addCase(fetchListAsnyc.fulfilled, (state, action) => {
        state.listData = action.payload.list;
        state.total = action.payload.count;
        state.loading = false;
      })
      .addCase(fetchZoneListAsync.fulfilled, (state, action) => {
        state.zoneList = action.payload;
      });
  },
});

export const { setParams, setSelects, resetCategory } = categorySlice.actions;
export const selectSelectIds = (state) => state.category.selectIds;
export const selectParams = (state) => state.category.params;
export const selectListObj = (state) => {
  return {
    total: state.category.total,
    listData: state.category.listData,
    loading: state.category.loading,
  };
};
export const selectZoneList = (state) => state.category.zoneList;
export default categorySlice.reducer;
