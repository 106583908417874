import { createIntl, createIntlCache } from "react-intl";

const cache = createIntlCache();
export const getIntl = (lang) => {
  let intl;
  if (lang === "zh-CN") {
    intl = createIntl({ locale: "zh-CN", messages: require("./zh-CN.json") }, cache);
  }else if(lang === "es-ES") {
    intl = createIntl({ locale: "es-ES", messages: require("./es-ES.json") }, cache);
  }else {
    intl = createIntl({ locale: "en-US", messages: require("./en-US.json") }, cache);
  }
  return intl;
};
