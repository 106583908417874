import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// 购物车 redux
import {
  fetchCartList,
  deleteCartItem,
  moveCartItem,
  addCart,
  updateCart,
  createOrder,
  createReservation,
} from "./api";

const initialState = {
  cartList: [],
  cartProductNum: 0,
  loading: false,
};

export const getCartListAsync = createAsyncThunk("cart/getCartList", async (payload) => {
  return await fetchCartList(payload);
});
//  新增一个选择筛选数据
export const getTopCartListAsync = createAsyncThunk("cart/getFobCartList", async (payload) => {
  return await fetchCartList(payload);
});

export const changeCartItem = createAsyncThunk("cart/changeCartItem", async (payload) => {
  return await updateCart(payload);
});
export const addCartItem = createAsyncThunk("cart/addCartItem", async (payload) => {
  return await addCart(payload);
});
export const delCartItemAsync = createAsyncThunk("cart/delCartItem", async (payload) => {
  return await deleteCartItem(payload);
});
export const moveCartItemAsync = createAsyncThunk("cart/moveItem", async (payload) => {
  return await moveCartItem(payload);
});

export const setCollectionAsync = createAsyncThunk("cart/setCollection", async (payload) => {
  return await payload;
});

export const createOrderAsync = createAsyncThunk("cart/createOrder", async (payload, { dispatch }) => {
  const data = await createOrder(payload);
  // // 刷新购物车数量
  // dispatch(getCartListAsync({ logistics_mode: "self" }));
  // // 创建订单 默认美仓
  // dispatch(getTopCartListAsync({logistics_mode:'fob'}))
  return data;
});

export const createReservationOrderAsync = createAsyncThunk("cart/createReservation", async (payload) => {
  return await createReservation(payload);
});

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
     .addCase(getCartListAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cartList = payload.list;
        state.cartProductNum = payload.count;
      })
      .addCase(getCartListAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCartListAsync.rejected, (state) => {
        state.loading = false;
      })

      // 默认数据  保存数据 
      .addCase(getTopCartListAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cartList = payload.list;
        state.cartProductNum = payload.count;
      })
      .addCase(getTopCartListAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(getTopCartListAsync.rejected, (state) => {
        state.loading = false;
      })
      
     

      .addCase(delCartItemAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(delCartItemAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(delCartItemAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cartProductNum = payload.count;
      })
      .addCase(moveCartItemAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(moveCartItemAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(moveCartItemAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changeCartItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeCartItem.rejected, (state) => {
        state.loading = false;
      })
      .addCase(changeCartItem.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cartProductNum = payload.count;
      })
      .addCase(addCartItem.fulfilled, (state, { payload }) => {
        state.cartProductNum = payload.count;
      })
      .addCase(setCollectionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(setCollectionAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(setCollectionAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createOrderAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOrderAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createOrderAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createReservationOrderAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createReservationOrderAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createReservationOrderAsync.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export const selectCartList = (state) => state.cart.cartList;
export const selectCartLoadig = (state) => state.cart.loading;
export const selectCartProductNum = (state) => state.cart.cartProductNum;
export default cartSlice.reducer;
