import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchOrderInfo, 
  toBalancePay,
   paypalPay, 
   stripePay, 
  saveAddress, 
  getLogisticsPrice,
  convergePay,
  offlinePaymentPay,  //线下支付
 } from "./api";
// 调用支付方法
// convergePay  //华美


const initialState = {
  payment: 1,
  paymentData: {},
  paying: false,
  addressLoading: false,
  pageLoading: false,
  pageType: "payForm",
  orderInfo: {},
  addressInfo: {},
};

export const getOrderInfoAsync = createAsyncThunk("pay/getOrderInfo", async (payload) => {
  let promissArr = [fetchOrderInfo(payload)];
  if (payload.quote_number) {
    promissArr.push(getLogisticsPrice(payload));
  }
  const datas = await Promise.all(promissArr);
  let orderInfo = datas[0];
  if (datas[1]) {
    orderInfo.extraPrice = {
      freight: datas[1].price,
    };
  }
  return orderInfo;
});

export const balancePayingAsync = createAsyncThunk("pay/balancePaying", async (payload) => {
  return await toBalancePay(payload);
});

export const paypalPayingAsync = createAsyncThunk("pay/paypalPaying", async (payload) => {
  return await paypalPay(payload);
});
export const stripePayAsync = createAsyncThunk("pay/stripePay", async (payload) => {
  return await stripePay(payload);
});
export const saveAddressAsync = createAsyncThunk("pay/address", async (payload) => {
  return await saveAddress(payload);
});
export const convergePayAsync = createAsyncThunk("pay/payResult", async (payload) => {
  return await convergePay(payload);
});
// 线下支付--->
export const offlinePaymentPayAsync = createAsyncThunk("pay/moneyTransfer", async (payload) => {
  return await offlinePaymentPay(payload);
});
export const paySlice = createSlice({
  name: "pay",
  initialState,
  reducers: {
    setPayment: (state, { payload }) => {
      state.payment = payload;
    },
    resetPayment: (state) => {
      state.payment = 1;
      state.paymentData = {};
      state.paying = false;
    },
    setPaymentData: (state, { payload = {} }) => {
      state.paymentData = { ...state.paymentData, ...payload };
    },
    setAddress: (state, { payload }) => {
      state.addressInfo = payload;
    },
    setOrderInfo: (state, { payload }) => {
      state.orderInfo = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderInfoAsync.fulfilled, (state, { payload }) => {
        state.pageLoading = false;
        state.orderInfo = payload;
        state.pageType = payload.status === 0 ? "payForm" : "paySuccess";
      })
      .addCase(getOrderInfoAsync.pending, (state) => {
        state.pageLoading = true;
      })
      .addCase(getOrderInfoAsync.rejected, (state) => {
        state.pageLoading = false;
      })
      .addCase(balancePayingAsync.pending, (state) => {
        state.paying = true;
      })
      .addCase(balancePayingAsync.rejected, (state) => {
        state.paying = false;
      })
      .addCase(balancePayingAsync.fulfilled, (state) => {
        state.paying = false;
        state.pageType = "paySuccess";
      })
      .addCase(paypalPayingAsync.pending, (state) => {
        state.paying = true;
      })
      .addCase(paypalPayingAsync.rejected, (state) => {
        state.paying = false;
      })
      .addCase(paypalPayingAsync.fulfilled, (state) => {
        state.paying = false;
      })
      .addCase(stripePayAsync.pending, (state) => {
        state.paying = true;
      })
      .addCase(stripePayAsync.rejected, (state) => {
        state.paying = false;
      })
      .addCase(stripePayAsync.fulfilled, (state) => {
        state.paying = false;
      })
      .addCase(saveAddressAsync.pending, (state) => {
        state.addressLoading = true;
      })
      .addCase(saveAddressAsync.rejected, (state) => {
        state.addressLoading = false;
      })
      .addCase(saveAddressAsync.fulfilled, (state) => {
        state.addressLoading = false;
      })
         // 华美
      .addCase(convergePayAsync.pending, (state) => {
        state.paying = true;
      })
      .addCase(convergePayAsync.rejected, (state) => {
        state.paying = false;
      })
      .addCase(convergePayAsync.fulfilled, (state) => {
        state.paying = false;
      })
      // 线下支付 --->线上转账
      // 线下支付 --->纸币支付
      // 线下支付 --->支票支付
      // 线下支付 --->其他支付
      .addCase(offlinePaymentPayAsync.pending, (state) => {
        state.paying = true;
      })
      .addCase(offlinePaymentPayAsync.rejected, (state) => {
        state.paying = false;
      })
      .addCase(offlinePaymentPayAsync.fulfilled, (state) => {
        state.paying = false;
      })
 
  },
});
export const { setPayment, setPaymentData, resetPayment, setAddress, setOrderInfo } = paySlice.actions;
export const selectLoading = (state) => ({ paying: state.pay.paying, pageLoading: state.pay.pageLoading });
export const selectAddressLoading = (state) => state.pay.addressLoading;
export const selectPageType = (state) => state.pay.pageType;
export const selectOrderInfo = (state) => state.pay.orderInfo;
export const selectPayment = (state) => ({ payment: state.pay.payment, paymentData: state.pay.paymentData });
export const selectAddress = (state) => state.addressInfo;
export default paySlice.reducer;
