import { getPrice } from "../../hooks/usePrice";
import request from "../../utils/request";

// 获取购物车数据
export const fetchCartList = (data = {}) => {
  return request.post("/supply/order/cart/list", data).then(({ list = [], count = 0 }) => ({
    list: list,
    // .map((item) => {
    //   item.showPrice = item.special_offer > 0 ? item.special_offer : item.price;
    //   item.totalPrice = getPrice(item.showPrice * item.amount);
    //   return item;
    // }),
    count,
  }));
};

export const createFobOrder = (params) => {
  return request.post("/supply/order/order/fob", params);
};

// 获取合同商品数据
export const getContractGoods = (params) => {
  return request.post("/supply/order/cart/ContractList", params);
};


// 删除合同商品数据
export const delCreateContract = (params) => {
  return request.post("/supply/goods/goods/CreateContract", params);
};


export const deleteCartItem = (params) => {
  return request.post("/supply/order/cart/delete", params);
};

export const moveCartItem = (data) => {
  return request.post("/supply/order/cart/move", data);
};

export const addCart = (data) => {
  return request.post("/supply/order/cart/add", { ...data, is_update: 0 });
};
export const updateCart = (data) => {
  return request.post("/supply/order/cart/add", { ...data, is_update: 1 });
};
export const addFavorites = (goods_sn) => {
  return request.post("/supply/goods/collection/create", { goods_sn_list: goods_sn });
};
export const delFavorites = (goods_sn) => {
  return request.post("/supply/goods/collection/delete", { goods_sn });
};

export const createOrder = (data) => {
  return request.post("/supply/order/order/create", data);
};

export const getCoupon = (params) => {
  return request.post("/supply/member/coupon/list", params);
};

export const exchangeCoupon = (params) => {
  return request.post("/supply/order/coupon/exchange", params);
};

export const getGoodsInfo = (params) => {
  return request.post("/supply/goods/goods/info", params);
};

export const createReservation = (params) => {
  return request.post("/supply/order/order/booking", params);
};

export const getOrdersInfo = (params) => {
  return request.post("/supply/order/order/info", params);
};

export const createReservationFinal = (params) => {
  return request.post("/supply/order/order/replenish", params);
};
