import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import { selectMessageInfo, closeMessageInfo, selectInitFinish, selectIsLogin } from "../../app/appSlice";
import { routesData } from "../../route";
import { Alert, Backdrop, Box, CircularProgress, Snackbar, Typography, Stack, LinearProgress } from "@mui/material";
import { initAsync } from "../../app/appSlice";

import { Container } from "@mui/system";

const MessageBox = () => {
  const dispatch = useDispatch();
  const { show, info = {} } = useSelector(selectMessageInfo);
  const { type, message, ...props } = info;
  const handleClose = () => {
    dispatch(closeMessageInfo());
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      autoHideDuration={1500}
      onClose={handleClose}
      open={show}
      sx={{ left: "50%!important", transform: "translateX(-50%)", right: "auto" }}
      {...props}
    >
      <Alert {...(!type ? { icon: false } : {})} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const RouterItem = (props) => {
  const isLogin = useSelector(selectIsLogin);
  const meta = props.meta || {};
  return !meta.checkLogin || isLogin ? (
    <React.Suspense
      fallback={
        <Container>
          <Box sx={{ width: "100%", color: "grey.300" }}>
            <LinearProgress color="inherit"></LinearProgress>
          </Box>
        </Container>
      }
    >
      <props.component />
    </React.Suspense>
  ) : (
    <Navigate replace to="/login"></Navigate>
  );
};

const Layout = () => {
  const dispatch = useDispatch();
  const initFinish = useSelector(selectInitFinish);
  useEffect(() => {
    dispatch(initAsync()).then(() => {
      let el = document.querySelector("#root-loading");
      if (el) {
        el.style.display = "none";
      }
    });
  }, []);
  const renderRoutes = (routes) => {
    return routes.map((o, index) => {
      if (o.replace) {
        return <Route key={index} path={o.path} element={<Navigate replace to={o.replace}></Navigate>}></Route>;
      } else {
        return (
          <Route key={o.path} path={o.path} element={<RouterItem component={o.component} meta={o.meta}></RouterItem>}>
            {o.children && renderRoutes(o.children)}
          </Route>
        );
      }
    });
  };

  return (
    <React.Fragment>
      {initFinish && <Routes>{renderRoutes(routesData)}</Routes>}
      <MessageBox />
    </React.Fragment>
  );
};

export default Layout;
