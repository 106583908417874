/**
 * @description 对接erp接口
 */
import request from "../utils/request";
/**
 * 
 * @description 请求ERP接口
 */
export const fetchErp = (data = {}) => {
  return request.post("/supply/goods/erp/requestErp", data)
}

