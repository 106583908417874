import { configureStore } from "@reduxjs/toolkit";
import menuReducer from "../components/Menu/menuSlice";
import payReducer from "../pages/Pay/paySlice";
import appReducer from "./appSlice";
import categoryReducer from "../pages/Category/categorySlice";
// 购物车  redux 数据
import cartReducer from "../pages/Cart/cartSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    menu: menuReducer,
    pay: payReducer,
    category: categoryReducer,
    cart: cartReducer,
  },
});
