/**
 * @description 常用正则表达式
 */

const validInteger = (val) => {
  if (!val) return
  const regex =  /(^[1-9]\d*$)/
  return regex.test(val)
}

/**
 * @description 验证金额（含零），保留两位小数(注：这里需要为string类型)
 */
const validMoney = (val) => {
  if (!val) return
  const regex = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
  return regex.test(String(val))
}


export {
  validInteger,
  validMoney
}