import { MenuItem, MenuList, Popper, Paper, Link, Stack, Button } from "@mui/material";
import React, { useMemo } from "react";
import { usePopupState, bindHover, bindFocus, bindPopper } from "material-ui-popup-state/hooks";
import { ChevronRight, Menu } from "@mui/icons-material";
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMenuList } from "../../app/appSlice";
const PopupContext = React.createContext({
  parentPopupState: null,
  rootPopupState: null,
});
const RightMenu = (props) => {
  const { info = [], onClick = () => {} } = props;
  return (
    <Paper className="right-menu-box">
      {info.map((o) => {
        const hasChildern = o.children && o.children.length !== 0;
        return (
          <Stack direction="column" key={o.type_id}>
            <Link className="right-menu-sub-link" underline="none" fontSize={15} onClick={() => onClick(o.type_id)}>
              {o.title}
            </Link>
            {hasChildern && (
              <Stack spacing={1}>
                {o.children.map((i) => (
                  <Link
                    key={i.type_id}
                    className="right-menu-link"
                    fontSize={14}
                    underline="none"
                    onClick={() => onClick(i.type_id)}
                  >
                    {i.title}
                  </Link>
                ))}
              </Stack>
            )}
          </Stack>
        );
      })}
    </Paper>
  );
};

const ListItem = (props) => {
  const { parentPopupState, rootPopupState } = React.useContext(PopupContext);
  const popupState = usePopupState({
    popupId: "item" + props.info.type_id,
    variant: "popover",
    parentPopupState,
  });
  const handleClick = (id) => {
    rootPopupState.close();
    if (props.onClick) {
      props.onClick(id);
    }
  };
  const hasChildren = props.info.children && props.info.children.length > 0;
  return (
    <React.Fragment>
      <MenuItem
        {...props}
        {...bindHover(popupState)}
        {...bindFocus(popupState)}
        onClick={() => handleClick(props.info.type_id)}
      >
        <span style={{ flexGrow: 1, fontSize: 14, color: "#666" }}>{props.info.title}</span>
        {hasChildren && <ChevronRight sx={{ color: "#999" }} />}
      </MenuItem>
      {hasChildren && (
        <Popper sx={{ zIndex: 9999 }} placement="right" {...bindPopper(popupState)}>
          <RightMenu info={props.info.children} onClick={handleClick}></RightMenu>
        </Popper>
      )}
    </React.Fragment>
  );
};

const ButtonMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const menuList = useSelector(selectMenuList);
  const handleClick = (id) => {
    navigate("/category?category_id=" + id);
  };
  const popupState = usePopupState({
    popupId: "buttonMenu",
    variant: "popover",
  });
  const { rootPopupState } = React.useContext(PopupContext);
  const context = React.useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState]
  );
  const showPaper = useMemo(() => {
    return pathname !== "/home";
  }, [pathname]);
  return (
    <div className="component-button-menu">
      <PopupContext.Provider value={context}>
        <Button variant="contained" {...bindHover(popupState)} sx={{ width: 230, borderRadius: 0 }}>
          <Menu sx={{ mr: 1 }} /> ALL CATEGORIES
        </Button>
        {/*下面菜单 */}
        {showPaper && (
          <Popper sx={{ zIndex: 9999 }} {...bindPopper(popupState)} placement="bottom-start">
            <Paper sx={{ width: 230 }}>
              <MenuList>
                {menuList.map((item) => (
                  <ListItem key={item.type_id} info={item} onClick={handleClick}></ListItem>
                ))}
              </MenuList>
            </Paper>
          </Popper>
        )}
      </PopupContext.Provider>
    </div>
  );
};

export default ButtonMenu;
