import request from  "../../../utils/request";
// 订单列表
export const getOrderList = (params) => {
  return request.post("/supply/order/order/list", params);
};
// 获取的订单详情
export const getOrderInfo = (params) => {
  return request.post("/supply/order/order/info", params);
};
export const deleteItem = (params) => {
  return request.post("/supply/order/order/delete", params);
};
export const cancelOrder = (params) => {
  return request.post("/supply/order/order/cancel", params);
};

// 获取预售单信息
export const getOrderPresellInfo = (params) => {
  return request.post("/supply/order/order/OrderPreSaleInfo", params);
};

// 提交预售单信息
export const submitAdvance = (data) => {
  return request.post("/supply/order/order/OrderPreSaleCreate", data);
};


// 提交尾款支付凭证
export const submitBalancePayment = (data) => {
  return request.post("/supply/order/order/OfflineOrderUploadCredentials", data);
};



// 第一步订金审核
export const submitDeposit = (data) => {
  return request.post("/supply/order/fob/deposit", data);
};
// 验货资料提交
export const submitInspectGoods = (data) => {
  return request.post("/supply/order/fob/checkData", data);
};
// 第二步验证资料
export const submitVerificationData = (data) => {
  return request.post("/supply/order/fob/shipStatus", data);
};

// 第三步尾款审核
export const submitFinalPay = (data) => {
  return request.post("/supply/order/fob/finalPay", data);
};

// 第四步验收信息
export const submitInspection = (data) => {
  return request.post("/supply/order/fob/inspection", data);
};
// 合同完毕
export const submitCompletionContract = (data) => {
  return request.post("/supply/order/fob/auditContractCompleteImg", data);
};
// FOB订单添加收货地址、编辑收货地址
export const fobAddress = (data) => {
  return request.post("/supply/order/fob/saveOrderFobAddress",data);
};

// 查看地址
export const getFobAddress = (data) => {
  return request.post("/supply/order/fob/getOrderFobAddress",data);
};

// 确定订购合同接口
export const SubmitOrderContract = (data) => {
  return request.post("/merchant/order/fob/CreatePurchaseContract",data);
};

// 获取订购合同详情
export const getOrderContractInfo = (data) => {
  return request.post("/supply/order/fob/purchaseContractInfo",data);
};

// 获取合同的编号
export const getContractSn = (data) => {
  return request.post("/supply/order/fob/getContractSn",data);
};
// 通过fob_id获取 当前订单号信息

export const getFobGoodsData = (data) => {
  return request.post("/supply/order/fob/getFobGoodsData",data);
};

/**
//  * @Description 购物车合同商品添加
//  * @Author Ahao
//  */
export const addPurchasingCart = (data) => {
  return request.post("/supply/goods/goods/CreateContract", data)
}

/**
 * @description 购物车合同商品列表获取
 * @param {Object} data 
 * @returns 
 */
export const getContractList = (data) => {
  return request.post("/supply/order/cart/ContractList", data)
}
