import { useIntl } from "react-intl";
export const useFormate = (module) => {
  const { formatMessage: _formatMessage } = useIntl();
  const formatMessage = (id, obj) => {
    return _formatMessage({ id: `${module}.${id}`, defaultMessage: id }, obj);
  };
  const formatMsg = (id, obj) => {
    if (!id) id = "-";
    return _formatMessage(typeof id === "string" ? { id, defaultMessage: id } : id, obj);
  };
  return { formatMessage, formatMsg };
};
