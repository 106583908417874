import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(0, 46, 166)",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    // 修改购物车 提示点样式
    tagBlack: {
      main: "#FFCE22",
      contrastText: "#fff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 820,
      lg: 1410,

      xl: 1740, //整体设置宽度
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
      },
    },
    MuiUseMediaQuery: {
      defaultProps: { noSsr: true },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          textTransform: "initial",
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "initial",
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiStack: {
      defaultProps: {
        direction: "row",
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
        sx: {
          cursor: "pointer",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ".MuiInputLabel-asterisk": {
            color: "red",
          },
        },
      },
    },
  },
});

export default theme;
