// A mock function to mimic making an async request for data
export function fetchMenu() {
  const menuData = [
    {
      id: "1",
      title: "123",
      children: [
        {
          id: "1-1",
          title: "123-123",
          children: [
            { id: "1-1-1", title: "123-123-123123-123-123123-123-123123-123-123 123-123-123" },
            { id: "1-1-2", title: "123-123-456" },
          ],
        },
        { id: "1-2", title: "123-456" },
        { id: "1-3", title: "123-55" },
      ],
    },
    {
      id: "33333",
      title: "456",
      children: [
        {
          id: "33333-1",
          title: "123-123",
          children: [
            { id: "33333-1-1", title: "123-123-123" },
            { id: "33333-1-2", title: "123-123-456" },
          ],
        },
        { id: "33333-2", title: "123-456" },
        { id: "33333-3", title: "123-55" },
      ],
    },
  ];
  return new Promise((resolve) => setTimeout(() => resolve({ data: menuData }), 500));
}
