import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccount, fetchInit, fetchMenu, fetchUserInfo, fetchVerify, toLogin, fetchGoodsConfig } from "./api";
const uuidKey = "uuid_str";
const tokenKey = "token";
const tokenErpKey = "token_erp";
const langKey = "active_lang";
const userType = "user_type";
const internalUser = "internal_user";
// 定义信息类型
const newType = 'new_type'
const userUuid = 'user_uuid'
// 共的运营商
const commonOperator = 'common_operator'
// 用户绑定私有库现在状态
const userWarehouseType = 'user_warehouse_type'
const operatorName = 'operator_name'
const jumpToken = new URLSearchParams(window.location.search).get('token')
const token = localStorage.getItem(tokenKey) || "";
const token_erp = localStorage.getItem(tokenErpKey) || "";
const user_type = localStorage.getItem(userType) || ""; //用户类型
const internal_user = localStorage.getItem(internalUser) || ""; //用户类型
const user_warehouse_type = localStorage.getItem(userWarehouseType) || ""; //用户类型
const uuid = localStorage.getItem(uuidKey) || "";
const activeLang = localStorage.getItem(langKey) || "en-US";

const newTypeCk = localStorage.getItem(newType) || "";
const user_uuid = localStorage.getItem(userUuid) || ''
const operator_name = localStorage.getItem(operatorName) || ''
const commonOperatorObject = {
  // LA
  client_id: "05947269488",
  client_secret: "A5rwafSC5jkscIkDVfDIuG43EO7EUqZO",
};

// 初始化状态
const initialState = {
  token,
  token_erp,  //erp用户系统token
  user_type,  //用户类型
  internal_user, //是否是内部用户
  user_warehouse_type, //用户绑定私有库现在状态
  newTypeCk,  //新类型
  activeLang,
  uuid,
  currency: "$",
  languages: {},
  shop_sn: "",
  logo: "",
  initFinish: false,
  messageShow: false,
  messageInfo: {},
  menuList: [],
  loginLoading: false,
  userInfo: {},
  verifyInfo: {},
  accountInfo: {},
  withdrawal_handling: {},
  recharge_handling: {},
  logoutModal: false,
  // 公共运营商
  commonOperatorObject,
  user_uuid,
  // 站点
  operator_name,
};

export const initAsync = createAsyncThunk("app/initData", async (amount, thunkApi) => {

  const { getState, dispatch } = thunkApi;
  const { setUUID, setInitData } = appSlice.actions;

  let data;
  if (getState().app.uuid) {
    const { list } = await fetchInit();
    data = list[0];
  } else {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    dispatch(setUUID(result.visitorId));
    const { list } = await fetchInit();
    data = list[0];
  }
  //如果跳转过来有token 直接设置token请求数据
  jumpToken && localStorage.setItem(tokenKey, jumpToken)
  // 请求用户详情
  if(jumpToken){
    dispatch(setInitData(data));
    await dispatch(fetchUserAsync())
    await dispatch(fetchGoodPriceConfig())
  }else{
    await dispatch(fetchUserAsync())
    await dispatch(fetchGoodPriceConfig())
    dispatch(setInitData(data));
  }
  return await fetchMenu();
});

export const fetchAccountAsync = createAsyncThunk("app/loadAccount", async () => {
  return await fetchAccount();
});

export const fetchUserAsync = createAsyncThunk("app/fetchUserInfo", async () => {
  return await fetchUserInfo();
});

export const fetchVerifyAsync = createAsyncThunk("app/fetchVerify", async () => {
  return await fetchVerify();
});

export const loginAsync = createAsyncThunk("app/login", async (data) => {
  return await toLogin(data);
});

export const fetchGoodPriceConfig = createAsyncThunk("app/fetchGoodsConfig", async () => {
  return await fetchGoodsConfig();
});

// 设置初始化值
export const appSlice = createSlice({
  // 下面app,根据name 定义设置数据
  name: "app",
  initialState,
  reducers: {
    setLogoutModal: (state, { payload }) => {

      state.logoutModal = payload;
    },
    loginOut: (state) => {
      localStorage.setItem(tokenKey, "");

      // 设置erp token
      localStorage.setItem(tokenErpKey, "")
      localStorage.setItem(operatorName, "")

      state.token = "";
      // 退出登录  erp_token 为空
      state.token_erp = "";
      state.userInfo = {};
    },

    // 原有token登录
    setToken: (state, { payload }) => {
      localStorage.setItem(tokenKey, payload);
      state.token = payload;
    },
    resetToken: (state) => {
      localStorage.setItem(tokenKey, "");
      state.token = "";
    },
    //  新增一个token_erp
    setErpToken: (state, { payload }) => {
      // console.log('设置token',state)
      localStorage.setItem(tokenErpKey, payload);
      state.token_erp = payload;
    },
    resetErpToken: (state) => {
      localStorage.setItem(tokenErpKey, "");
      state.token_erp = "";
    },
    //  保存用户信息
    setUserType: (state, { payload }) => {
      // console.log('用户类型',state,payload)
      localStorage.setItem(userType, payload);
      state.user_type = payload;
    },
    //  保存用户类型
    setInternalUser: (state, { payload }) => {
      localStorage.setItem(internalUser, payload);
      state.internal_user = payload;
    },

    // 保护同步私有库类型
    setUserWarehouseType: (state, { payload }) => {
      localStorage.setItem(userWarehouseType, payload);
      state.user_warehouse_type = payload;
    },

    setNewType: (state, { payload }) => {

      localStorage.setItem(newType, payload);
      state.newTypeCk = payload;
    },

    setActiveLang: (state, { payload }) => {
      localStorage.setItem(langKey, payload);
      state.activeLang = payload;
    },
    setMessageInfo: (state, { payload = {} }) => {
      state.messageInfo = typeof payload !== "object" ? { message: payload, type: "info" } : payload;
      state.messageShow = true;
    },
    closeMessageInfo: (state) => {
      state.messageShow = false;
    },
    setUUID: (state, { payload }) => {
      state.uuid = payload;
      localStorage.setItem(uuidKey, payload);
    },
    setInitData: (state, { payload }) => {
      // 初始化数据
      state.currency = payload.currency;
      state.languages = payload.language;

      state.shop_sn = payload.shop_sn;
      state.title = payload.title;
      state.logo = payload.logo;
      state.withdrawal_handling = payload.withdrawal_handling;
      state.recharge_handling = payload.pay_handling;
      document.title = payload.title;
      if (jumpToken) {
        let newtoken = localStorage.getItem(tokenKey)
        state.token = newtoken ;
        state.token_erp = newtoken ;
      }
     
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initAsync.fulfilled, (state, action) => {
        state.menuList = action.payload;
        state.initFinish = true;
      })
      .addCase(loginAsync.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(loginAsync.rejected, (state) => {
        state.loginLoading = false;
      })
      .addCase(loginAsync.fulfilled, (state, { payload }) => {
        state.loginLoading = false;
        state.token = payload.token;
        // 用户类型
        state.user_type = payload.user_type;
        localStorage.setItem(userType, payload.user_type)

        state.internal_user = payload.internal_user;
        localStorage.setItem(internalUser, payload.internal_user)
        // 用户私有库类型
        state.user_warehouse_type = payload.user_warehouse_type;
        localStorage.setItem(userWarehouseType, payload.user_warehouse_type)

        // 添加新类型
        state.newTypeCk = payload.user_sn;
        localStorage.setItem(newType, payload.user_sn)

        // 测试erpToken  start
        state.token_erp = payload.token;
        // 设置本地缓存ERP_TOKEN
        localStorage.setItem(tokenErpKey, payload.token)
        // end
        localStorage.setItem(tokenKey, payload.token);
        // 设置 user_uuid

        state.user_uuid = payload.uuid;
        localStorage.setItem(userUuid, payload.uuid);
        // 站点
        state.operator_name = payload.operator_name;
        localStorage.setItem(operatorName, payload.operator_name);

        // 存储登录的用户
        state.userInfo = payload

      })
      .addCase(fetchUserAsync.fulfilled, (state, { payload }) => {
        console.log('4444',jumpToken)
        if (jumpToken) {

          console.log('payload',payload)
          let newtoken = localStorage.getItem(tokenKey)
          state.token = newtoken ;
          state.token_erp = newtoken ;
          state.userInfo = payload.user_info;
          state.user_type = payload.user_info.user_type;
          localStorage.setItem(userType, payload.user_info.user_type)

          state.internal_user = payload.user_info.is_internal_user;
          localStorage.setItem(internalUser, payload.user_info.is_internal_user)
        } else {
          if (payload.login_status === 0) {
            localStorage.setItem(tokenKey, "");
            // 用户信息里面   清楚erptoken
            localStorage.setItem(tokenErpKey, "");
            state.token = "";
            state.userInfo = {};
          } else {
            // console.log('user_type',user_type)
            state.userInfo = payload.user_info;
          }
        }
      })
      .addCase(fetchAccountAsync.fulfilled, (state, { payload }) => {
        state.accountInfo = payload;
      })
      .addCase(fetchVerifyAsync.fulfilled, (state, { payload }) => {
        state.verifyInfo = payload;
      })
      // 定义价格是否放开配置
      .addCase(fetchGoodPriceConfig.fulfilled, (state, { payload }) => {
        localStorage.setItem("showGoodsPrice", payload.data.price_switch);
      })
  },
});

export const { setToken, resetToken, setErpToken, resetErpToken, loginOut, setActiveLang, setMessageInfo, closeMessageInfo, setLogoutModal, setUserType, setInternalUser, setCommonOperatorObject, setUserWarehouseType } =
  appSlice.actions;
export const selectMessageInfo = (state) => ({ info: state.app.messageInfo, show: state.app.messageShow });
export const selectLanguages = (state) => state.app.languages;
export const selectActiveLanguage = (state) => state.app.activeLang;
export const selectInitFinish = (state) => state.app.initFinish;
export const selectMenuList = (state) => state.app.menuList;
export const selectCurrency = (state) => state.app.currency;
export const selectIsLogin = (state) => !!state.app.token;
export const selectLoginLoading = (state) => state.app.loginLoading;
export const selectUserInfo = (state) => state.app.userInfo;
export const selectLogo = (state) => state.app.logo;
export const selectAccountInfo = (state) => state.app.accountInfo;
export const selectWithdrawalHandling = (state) => state.app.withdrawal_handling;
export const selectRechargeHandling = (state) => state.app.recharge_handling;
export const selectVerifyInfo = (state) => state.app.verifyInfo;
export const selectLogoutModal = (state) => state.app.logoutModal;
export default appSlice.reducer;
