import { Outlet } from "react-router-dom";
import React from "react";
import Header from "../Header";
import MenuBar from "../MenuBar";
import Footer from "../Footer";
import FloatCart from "../FloatCart";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
const DefaultLayout = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);
  return (
    <React.Fragment>
      <Header></Header>
      <MenuBar></MenuBar>
      <Outlet></Outlet>
      <Footer></Footer>
      <FloatCart></FloatCart>
    </React.Fragment>
  );
};

export default DefaultLayout;
