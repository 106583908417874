import { AddShoppingCart, LocalShippingOutlined, DeleteOutline, CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Grid,
  Typography,
  Fab,
  Button,
  SwipeableDrawer,
  Stack,
  IconButton,
  FormControlLabel,
  CircularProgress,
  Badge,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { selectCurrency, selectIsLogin, setMessageInfo } from "../../app/appSlice";
import { useFormate } from "../../hooks/useFormate";
import {
  selectCartList,
  getCartListAsync,
  changeCartItem,
  delCartItemAsync,
  createOrderAsync,
  selectCartLoadig,
  selectCartProductNum,
  getTopCartListAsync,  //fob  redux方法 请求数据
  getContactCartListAsync, //合同商品
} from "../../pages/Cart/cartSlice";
import { useKeys, useHandleData, useSelects } from "../../pages/Cart/hooks";
import { useCalcCartPrice, useCartPriceObj } from "../../hooks/usePrice";
import ContractAddressDialog from "../../pages/Product/components/ContractAddressDialog";
import Empty from "../Empty";
import ImgBox from "../ImgBox";
import NumInput from "../NumInput";
import { store } from "../../app/store";
import caetImg from '../../assets/img/cart.png';
import testImage from '../../assets/images/F32113.png';
import './index.scss'
// 创建fob订单
import { createFobOrder, getContractGoods, delCreateContract } from "../../pages/Cart/api";
const activeKeys = ["self",];
const activeKeys2 = ["self", "ship"];
const FloatCart = () => {
  const { formatMessage, formatMsg } = useFormate("cart");
  const [drawerShow, setDrawerShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currency = useSelector(selectCurrency);
  const loading = useSelector(selectCartLoadig);
  const [contactLoading, setContactLoading] = useState(false);
  const isLogin = useSelector(selectIsLogin);
  const cartList = useSelector(selectCartList);
  const { getPriceObj } = useCartPriceObj();
  const { calcCartPrice } = useCalcCartPrice();
  const { activeType, classKeys, setActiveType } = useKeys();
  const { showList, classObj, handleShowList } = useHandleData(classKeys);
  const [searchParams] = useSearchParams();
  //订单数据
  const [orderData, setOrderData] = useState({
    open: false, //是否打开
    select_ids: [], //商品选择的ids
    merchant_uuid: '', //商户id
  });

  // console.log('showList',showList)
  const cartProductNum = useSelector(selectCartProductNum);
  const { selects, selectStatus, setSelects, handleSelect, handleClassSelect, itemIsCheck, setSelectsBySource } =
    useSelects(classKeys, classObj);
  // console.log('勾选的数据',selects,selectStatus)
  // redux  筛选数据
  const getList = (logistics_mode = activeType) => {
  
    dispatch(getCartListAsync({ logistics_mode }));
  };
  // 用户类型
  const { getState } = store;
  const { internal_user } = getState().app;

  const handleNumChange = (val, info) => {
    let params = {
      goods_sn: info.goods_main_sn,
      merge_sn: info.merge_sn,
      warehouse_id: info.warehouse_id,
      amount: val,
    };
    if (activeType !== "fob") {
      if (activeType === "self") {
        params.self_delivery = 1;
        params.mode = 1;
      } else {
        params.drop_shipping = 1;
        params.mode = 2;
      }
    } else {
      params.is_fob = 1;
    }
    dispatch(changeCartItem(params)).then((data) => {
      if (!data.error) {
        getList();
      }
    });
  };
  // 合同商品数据
  const [contactLsit, setContactList] = useState([])
  const [selectsList, setSelectsList] = useState([])

  const [expanded, setExpanded] = useState(false);
  // 当前只能选择某一个商家
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setSelectsList([])
  };
  // 当前选中的
  const handleSelectItem = (checked, item, index) => {
    // console.log('当前选中状态', checked,index)
    // console.log('当前选中的数据', item)
    let selects = [...selectsList]
    if (checked) {
      selects.push(item)
      setSelectsList(selects)
    } else {
      let del_index = selects.findIndex(select => select.id === item.id)
      selects.splice(del_index, 1)
      setSelectsList(selects)
    }
  }

  //  fob商品进步器选择
  const handleNumChangeFob = (val, info) => {
    console.log('合同商品进步器', val)
    // let params = {
    //   goods_sn: info.goods_main_sn,
    //   merge_sn: info.merge_sn,
    //   amount: val,
    //   is_fob: 1,
    //   mode: 3,
    // };
    // dispatch(changeCartItem(params)).then((data) => {
    //   if (!data.error) {
    //     let logistics_mode = 'fob'
    //     dispatch(getCartListAsync({ logistics_mode }))
    //   }
    // });
  }
  // 特价订单进步器选择
  const handleNumChangeSpecial = (val, info) => {
    let params = {
      goods_sn: info.goods_main_sn,
      merge_sn: info.merge_sn,
      amount: val,
      warehouse_id: info.warehouse_id,
      mode: 4,
    };
    dispatch(changeCartItem(params)).then((data) => {
      if (!data.error) {
        let logistics_mode = 'special'
        dispatch(getCartListAsync({ logistics_mode }))
      }
    });
  }

  const handlePayment = () => {
    let warehouses = {};
    // console.log('购物车数据',selects)
    let goods_list = selects.map((item) => {
      warehouses[item.warehouse_id] = true;
      return {
        goods_sn: item.goods_sn,
        warehouse_id: item.warehouse_id,
        amount: item.amount,
        is_chose_data: item.is_chose_data,
      };
    });

    if (Object.keys(warehouses).length > 1) {
      dispatch(setMessageInfo(formatMsg("tips.warehouseLimit")));
      return;
    }
    let params = { logistics_mode: activeType, goods_list: JSON.stringify(goods_list), type: 1 };
    dispatch(createOrderAsync(params)).then((data) => {
      // console.log('赛选的数据',data.payload)
      if (!data.error) {
        setDrawerShow(false);
        navigate(`/pay?order_sn=${data.payload.order_sn}`);
      }
    });
  };

  const handlePaymentFob = () => {
    const ides = selectsList.map(item => item.id)
    setOrderData({
      open: true,
      select_ids: ides,
      merchant_uuid: selectsList[0].merchant_uuid, //默认拿数组下标第一个就是当前商家uuid
    })
  }
  //处理弹窗关闭
  const handleContractClose = (action) => {
    //重新获取数据
    if (action && action === 'clear') { //是否清除选中数据
      setContactList([]) //重置数据进行请求
      setSelectsList([])
    }
    getConactList()
    setOrderData({
      open: false,
      select_ids: [],
      merchant_uuid: ''
    })
  }

  // 特价商品下单模块
  const handlePaymentSpecial = () => {
    let warehouses = {};
    let goods_list = selects.map((item) => {
      warehouses[item.warehouse_id] = true;
      return {
        goods_sn: item.goods_sn,
        warehouse_id: item.warehouse_id,
        amount: item.amount,
      };
    });
    if (Object.keys(warehouses).length > 1) {
      dispatch(setMessageInfo(formatMsg("tips.warehouseLimit")));
      return;
    }
    let params = { logistics_mode: activeType, goods_list: JSON.stringify(goods_list), type: 4, };
    dispatch(createOrderAsync(params)).then((data) => {
      if (!data.error) {
        setDrawerShow(false);
        navigate(`/pay?order_sn=${data.payload.order_sn}`);
      }
    });
  }


  const handleDel = (item) => {
    let postDelData = {
      goods_sn: item.goods_sn,
      logistics_mode: item.logistics_mode,
    }
    // fob删除
    if (item.logistics_mode == 'fob') {
      let logistics_mode = item.logistics_mode
      dispatch(delCartItemAsync(postDelData)).then((data) => {
        if (!data.error) {
          dispatch(getCartListAsync({ logistics_mode }));
          handleSelect(item, false);
        }
      });
    } if (item.logistics_mode == 'special') {
      // 特价商品删除
      let logistics_mode = item.logistics_mode
      dispatch(delCartItemAsync(postDelData)).then((data) => {
        if (!data.error) {
          dispatch(getCartListAsync({ logistics_mode }));
          handleSelect(item, false);
        }
      });
    } else {
      // 默认美国仓
      dispatch(delCartItemAsync(postDelData)).then((data) => {
        if (!data.error) {
          getList();
          handleSelect(item, false);
        }
      });
    }


  };

  const handleNewFobDel = (item) => {
    setContactLoading(true)
    delCreateContract({ id: item.id }).then((res) => {
      console.log(res)
      // 重新获取合同商品数据
      getConactList()
    })
  }
  const priceObj = useMemo(() => {
    return getPriceObj(selects);
  }, [selects]);

  const actualPrice = useMemo(() => {
    return calcCartPrice(priceObj);
  }, [priceObj]);
  const handleClick = () => {
    setDrawerShow(true);
  };
  const [checkPrice, setCheckPrice] = useState(0)
  const [checkPriceChina, setCheckPriceChina] = useState(0)
  // 预售
  const [checkAdvanceSale, setAdvanceSale] = useState(0)
  const [showFlag, setShowFlag] = useState(0)

  const handleChangeCheck = (type) => {

    switch (type) {
      case 1:
        setCheckPrice(0)
        setCheckPriceChina(0)
        setAdvanceSale(0)
        // getTopCartListAsync
        setShowFlag(0)
        dispatch(getTopCartListAsync({ logistics_mode: 'self' }));
          // 选中商品状态
        setSelects([]);
        break;
      case 2:
        setCheckPrice(1)
        setCheckPriceChina(1)
        setAdvanceSale(0)
        setShowFlag(1)
        setContactLoading(true)
        // 获取合同商品数据
        getConactList()
      
        break;
      // 特价
      case 3:
        setCheckPrice(1)
        setCheckPriceChina(0)
        setAdvanceSale(1)
        dispatch(getTopCartListAsync({ logistics_mode: 'special' }));
        setShowFlag(2)
        // 选中商品状态
        setSelects([]);
        break;
      default:
        break;
    }
  };
  // 获取合同商品数据
  const getConactList = () => {
    getContractGoods().then((res) => {
      setContactLoading(false)
      setContactList(res.list)
    })
  }

  useEffect(() => {
    if (isLogin && drawerShow) {
      setSelects([]);
      let routerType = searchParams.get('productType')
      if (routerType == 1) {
        setCheckPrice(0)
        setCheckPriceChina(0)
        setAdvanceSale(0)
        setShowFlag(0)
        if (activeType == 'ship') {
          dispatch(getTopCartListAsync({ logistics_mode: 'ship' }));
        } else {
          dispatch(getTopCartListAsync({ logistics_mode: 'self' }));
        }
      } else if (routerType == 2) {
        setCheckPrice(1)
        setCheckPriceChina(0)
        setAdvanceSale(1)
        dispatch(getTopCartListAsync({ logistics_mode: 'special' }));
        setShowFlag(2)
      } else {
        setCheckPrice(1)
        setCheckPriceChina(1)
        setAdvanceSale(0)
        setShowFlag(1)
        setContactLoading(true)
        // 获取合同商品数据
        getConactList()
      }
    }
  }, [activeType, isLogin, drawerShow]);
  // useEffect(() => {
  //   if (isLogin) {
  //     dispatch(getCartListAsync({ logistics_mode: "self" }));
  //   }
  // }, [isLogin]);
  useEffect(() => {
    handleShowList(cartList);
    setSelectsBySource(cartList);
  }, [cartList]);
  return (
    <React.Fragment>
      {isLogin && (
        <Fab
          size="small"
          color="primary"
          sx={(theme) => ({
            position: "fixed",
            top: "50%",
            right: 16,
            transform: "translateY(-50%)",
            borderRadius: 2,
            width: 55,
            height: 55,
            [theme.breakpoints.down("md")]: {
              height: 36,
              width: 36,
            },
          })}
          onClick={handleClick}
        >
          <AddShoppingCart
            sx={(theme) => ({
              fontSize: 28,
              [theme.breakpoints.down("md")]: {
                fontSize: 24,
              },
            })}
          ></AddShoppingCart>
          {/* 悬浮的购物测样式 */}
          <Badge
            badgeContent={cartProductNum}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              ".MuiBadge-badge": { backgroundColor: "#FFCE22", color: "#fff" },
            }}
          ></Badge>
        </Fab>
      )}
      <SwipeableDrawer
        open={drawerShow}
        anchor="right"
        onOpen={() => setDrawerShow(false)}
        onClose={() => setDrawerShow(false)}
      >
        <Stack
          direction={"column"}
          sx={(theme) => ({
            width: 500,
            height: "100%",
            position: "relative",
            [theme.breakpoints.down("sm")]: { width: 350 },
          })}
        >
          <Stack
            direction="column"
            justifyContent={"space-between"}
            sx={{ p: 1, pt: 0.5, flex: "0 0 auto", position: "relative" }}
          >

            <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
              <img src={caetImg} style={{ width: "20px", height: "20px", marginRight: "5px" }} />
              {formatMessage("title")}
            </Typography>
            <Box sx={{ borderBottom: "1px solid #ccc" }}>
              {/* 美国出仓价格 */}
              {checkPrice == 0 && <Box sx={{
                minWidth: "120px", background: "#002EA6", textAlign: "center", borderRadius: "6px",
                paddingTop: "5px", paddingBottom: "5px", cursor: "pointer", marginRight: "10px", color: "#fff",
                marginTop: "10px",
              }}>{formatMsg('product.price')}</Box>
              }
              {checkPrice == 1 && <Box sx={{
                minWidth: "120px", border: "1px solid  #CCCCCC", textAlign: "center", borderRadius: "6px",
                paddingTop: "5px", paddingBottom: "5px", cursor: "pointer", marginRight: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                ":hover": {
                  border: "1px solid #002EA6",
                }
              }}
                onClick={() => handleChangeCheck(1)}
              >{formatMsg('product.price')}</Box>
              }

              {/* 中国离岸价 */}
              {checkPriceChina == 0 && <Box sx={{
                minWidth: "120px", border: "1px solid  #CCCCCC", textAlign: "center", borderRadius: "6px",
                paddingTop: "5px", paddingBottom: "5px", cursor: "pointer", marginRight: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                ":hover": {
                  border: "1px solid #002EA6",
                }
              }}
                onClick={() => handleChangeCheck(2)}
              > {formatMsg("product.us.fobNewTitle")}</Box>
              }
              {checkPriceChina == 1 && <Box sx={{
                minWidth: "120px", background: "#002EA6", textAlign: "center", borderRadius: "6px",
                paddingTop: "5px", paddingBottom: "5px", cursor: "pointer", marginRight: "10px", color: "#fff",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              >
                {formatMsg("product.us.fobNewTitle")}
              </Box>
              }

              {/* 特价区购物车按钮 */}
              {checkAdvanceSale == 0 && <Box sx={{
                minWidth: "120px", border: "1px solid  #CCCCCC", textAlign: "center", borderRadius: "6px",
                paddingTop: "5px", paddingBottom: "5px", cursor: "pointer", marginRight: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                ":hover": {
                  border: "1px solid #002EA6",
                }
              }}
                onClick={() => handleChangeCheck(3)}
              > {formatMsg("product.special.price")}</Box>
              }
              {checkAdvanceSale == 1 && <Box sx={{
                minWidth: "120px", background: "#002EA6", textAlign: "center", borderRadius: "6px",
                paddingTop: "5px", paddingBottom: "5px", cursor: "pointer", marginRight: "10px", color: "#fff",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              >{formatMsg("product.special.price")}</Box>
              }

            </Box>

            {internal_user == 1 && (showFlag == 2) && <Stack spacing={1} sx={{ marginTop: "8px" }}>
              {activeKeys.map((item) => (
                <Button
                  key={item}
                  disableRipple
                  size={"small"}
                  variant={activeType === item ? "contained" : "outlined"}
                  onClick={() => setActiveType(item)}
                >
                  {formatMsg(`warehouseType.${item}`)}
                </Button>
              ))}
            </Stack>
            }
            {internal_user == 2 && showFlag == 0 && <Stack spacing={1} sx={{ marginTop: "8px" }}>
              {activeKeys2.map((item) => (
                <Button
                  key={item}
                  disableRipple
                  size={"small"}
                  variant={activeType === item ? "contained" : "outlined"}
                  onClick={() => setActiveType(item)}
                >
                  {formatMsg(`warehouseType.${item}`)}
                </Button>
              ))}
            </Stack>
            }
            <IconButton onClick={() => setDrawerShow(false)} sx={{ position: "absolute", top: 0, right: 5 }}>
              <CloseOutlined></CloseOutlined>
            </IconButton>
          </Stack>

          {/* 不是fob商品下面数据 */}
          {checkPriceChina !== 1 && <Box sx={{ flex: 1, overflowY: "auto", borderTop: 1, borderBottom: 1, borderColor: "#bebebe" }}>
            {showList.length !== 0 &&
              showList.map((classItem) => {
                return (
                  <Box key={classItem.id}>
                    <Stack alignItems="center" sx={{ background: "#e1e1e1" }}>
                      <FormControlLabel
                        sx={{ ml: 0, color: "primary.main", fontSize: 14, mr: 1 }}
                        control={
                          <Checkbox
                            onChange={(e) => handleClassSelect(classItem.id, e.target.checked)}
                            checked={!!selectStatus.classCheck[classItem.id]?.checked}
                            indeterminate={!!selectStatus.classCheck[classItem.id]?.indeterminate}
                            color="primary"
                          ></Checkbox>
                        }
                      ></FormControlLabel>
                      <React.Fragment>
                        <Stack alignItems="center" color="#002ea6">
                          <LocalShippingOutlined sx={{ mr: 1 }}></LocalShippingOutlined>
                          <Typography variant="body2" color="#101010">
                            {formatMessage("table.pickUp", { text: classItem.name })}
                          </Typography>
                        </Stack>
                      </React.Fragment>
                    </Stack>
                    {classItem.children.map((product, index) => (
                      <React.Fragment key={index}>
                        <Grid
                          container
                          alignItems={"center"}
                          sx={{
                            position: "relative",
                            borderBottom: 1,
                            borderColor: "#e0e0e0",
                            py: 2,
                            borderBottomWidth:
                              index === classItem.children.length - 1 || product.bom_list?.length > 0 ? 0 : 1,
                          }}
                        >
                          <Grid item xs="auto" sx={{ position: "relative" }}>
                            <Checkbox
                              checked={itemIsCheck(product)}
                              onChange={(e) => handleSelect(product, e.target.checked)}
                              color="primary"
                            ></Checkbox>
                          </Grid>
                          <Grid item xs sx={{ minWidth: 150 }}>
                            <Stack spacing={1} sx={{ flex: 1 }} alignItems="center">
                              <ImgBox
                                src={product.preview}
                                width={50}
                                height={50}
                                disabledScale
                                sx={{ mx: 0, flex: "0 0 auto" }}
                              ></ImgBox>
                              <Typography variant="body2" fontSize={12} sx={{ wordBreak: "break-all" }}>
                                {/* {formatMsg("sku")}:{product.custom_sn || product.sku_sn} */}
                                {formatMsg("sku")}:{product.custom_sn || product.title}

                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs="auto" sx={{ pt: 1, pl: "42px" }}>
                            <Stack spacing={1} alignItems="center">
                              {/* 非fob商品进步器 */}
                              {product.logistics_mode == 'self' && <NumInput
                                value={product.amount}
                                onChange={(val) => handleNumChange(val, product)}
                              ></NumInput>}
                              {/* FOB商品进步器 */}
                              {/* {product.logistics_mode == 'fob' && <NumInput
                                value={product.amount}
                                onChange={(val) => handleNumChangeFob(val, product)}
                              ></NumInput>
                              } */}
                              {/* 特价订单进步器 */}
                              {product.logistics_mode == 'special' && <NumInput
                                value={product.amount}
                                onChange={(val) => handleNumChangeSpecial(val, product)}
                              ></NumInput>
                              }
                              <Typography
                                variant="body2"
                                color="primary.main"
                                fontWeight={700}
                                sx={{ flex: "0 0 auto", minWidth: 80, textAlign: "center" }}
                              >
                                {currency}
                                {product.totalPrice / 100}
                              </Typography>
                              <IconButton size="small" onClick={() => handleDel(product)}>
                                <DeleteOutline />
                              </IconButton>
                            </Stack>
                          </Grid>
                          {product.bom_list.length > 0 && (
                            <Typography
                              sx={{
                                background: "#ffa13b",
                                height: 16,
                                lineHeight: "16px",
                                px: 1,
                                position: "absolute",
                                top: 0,
                                left: 0,
                                color: "#fff",
                                borderBottomRightRadius: 8,
                                fontSize: 12,
                                zIndex: 99,
                                ml: "0!important",
                              }}
                            >
                              {formatMsg("combination")}
                            </Typography>
                          )}
                        </Grid>
                        {product.bom_list.length > 0 &&
                          product.bom_list.map((bomItem, index) => (
                            <Stack
                              key={index}
                              spacing={1}
                              alignItems={"center"}
                              justifyContent={"flex-end"}
                              sx={{
                                pl: "42px",
                                pr: 2,
                                py: 1,
                                background: "#f0f0f0",
                                border: 1,
                                borderColor: "#e0e0e0",
                                borderTop: 0,
                              }}
                            >
                              <ImgBox
                                src={bomItem.preview}
                                width={30}
                                height={30}
                                disabledScale
                                disabledPointer
                                sx={{ mx: 0, flex: "0 0 auto" }}
                              ></ImgBox>
                              <Typography variant="body2" color="#bbb" fontSize={12} sx={{ flex: 1 }}>
                                {formatMsg("sku")}:{bomItem.custom_sn || bomItem.sku_sn}
                              </Typography>
                              <Typography variant="body2" color="#707070" sx={{ flex: "0 0 60px" }}>
                                X{bomItem.amount}
                              </Typography>
                              <Typography variant="body2" color="#707070" sx={{ flex: "0 0 80px" }}>
                                {currency}
                                {bomItem.totalPrice / 100}
                              </Typography>
                            </Stack>
                          ))}
                      </React.Fragment>
                    ))}
                  </Box>
                );
              })}
            {showList.length === 0 && <Empty></Empty>}
          </Box>
          }
          {/* 不是合同商品(fob) */}
          {checkPriceChina !== 1 && <Stack direction={"column"} spacing={1} sx={{ px: 1, py: 2, flex: "0 0 auto" }}>
            <Stack justifyContent={"space-between"}>
              <Typography>{formatMsg("paymentText.totalItems")}</Typography>
              <Typography>
                + {currency}
                {priceObj.total / 100}
              </Typography>
            </Stack>
            <Stack justifyContent={"space-between"}>
              <Typography>{formatMsg("paymentText.discount")}</Typography>
              <Typography>
                - {currency}
                {priceObj.discount / 100}
              </Typography>
            </Stack>
            <Stack justifyContent={"space-between"}>
              <Typography>{formatMessage("actualPayment")}</Typography>
              <Typography sx={{ color: "primary.main", fontSize: 16, fontWeight: 700 }}>
                {currency} {actualPrice / 100}
              </Typography>
            </Stack>

            {/* 第一个默认美仓下单 */}
            {showFlag == 0 && <Button
              disabled={selects.length === 0}
              variant="contained"
              sx={{ display: "block", width: 1, mb: 1, mt: 1 }}
              onClick={handlePayment}
            >
              {formatMsg("btn.payment")}
            </Button>}
            {/* 第二个fob下单 */}
            {/* {showFlag == 1 && <Button
              disabled={selects.length === 0}
              variant="contained"
              sx={{ display: "block", width: 1, mb: 1, mt: 1 }}
              onClick={handlePaymentFob}
            >
              {formatMsg("btn.OrderConfirmation")}
            </Button>
            } */}
            {showFlag == 2 && <Button
              disabled={selects.length === 0}
              variant="contained"
              sx={{ display: "block", width: 1, mb: 1, mt: 1 }}
              onClick={handlePaymentSpecial}
            >
              {formatMsg("btn.payment")}
            </Button>
            }
          </Stack>
          }
          {/* fob商品数据或者合同商品数据 */}
          {checkPriceChina == 1 && <Box sx={{ flex: 1, overflowY: "auto", borderTop: 1, borderBottom: 1, borderColor: "#bebebe" }}>
            {contactLsit.length !== undefined && contactLsit.length > 0 &&
              <Box sx={{ width: "98%", margin: "0 auto", marginTop: "5px" }}>
                {contactLsit.length !== 0 &&
                  contactLsit.map((item,index) => {
                    return (
                    <Accordion expanded={expanded === index} onChange={handleChangeAccordion(index)}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box sx={{ border: "1px solid #ccc", width: "50px", height: "50px", marginRight: "10px" }}>
                            <img src={testImage} style={{ width: "100%", height: "100%", }} />
                          </Box>
                          <Typography>{item.label}</Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: "10px" }}>
                        {/* 下面商家数据 */}
                        {item.child.length !== 0 && <Box>
                          {item.child.map((itemChildren, index) => (
                            <Box className="newFob_css">
                              <FormControlLabel
                                sx={{ ml: 0, color: "primary.main", fontSize: 14, mr: 1 }}
                                control={
                                  <Checkbox
                                    onChange={(e) => handleSelectItem(e.target.checked, itemChildren, index)}
                                    checked={selectsList.some(item => item.id === itemChildren.id)}
                                    color="primary"
                                  ></Checkbox>
                                }
                              ></FormControlLabel>
                              <Box sx={{ border: "1px solid #ccc", width: "60px", height: "60px", }}>
                                <img src={itemChildren.preview} style={{ width: "100%", height: "100%", }} />
                              </Box>
                              {/* ksu */}
                              <Box className="new_title_css">
                                <Typography>{formatMsg("sku")}:{itemChildren.custom_sn}</Typography>
                              </Box>
                              <Box sx={{ marginRight: "10px" }}>
                                {/*<NumInput*/}
                                {/*  value={itemChildren.num}*/}
                                {/*  onChange={(val) => handleNumChangeFob(val)}*/}
                                {/*></NumInput>*/}
                                {formatMsg("qty")}:
                                {itemChildren.num}
                              </Box>

                              {/* 合同商品删除 */}
                              <IconButton size="small" onClick={() => handleNewFobDel(itemChildren)}>
                                <DeleteOutline />
                              </IconButton>
                            </Box>
                          ))}
                        </Box>
                        }
                      </AccordionDetails>
                    </Accordion>
                    );
                  })}
              </Box>
            }

            {contactLsit.length == 0 && <Empty></Empty>}
          </Box>
          }
          {/* 合同商品(fob) */}
          {checkPriceChina == 1 && <Stack direction={"column"} spacing={1} sx={{ px: 1, py: 2, flex: "0 0 auto" }}>
            {/*<Stack justifyContent={"space-between"}>*/}
            {/*  <Typography>{formatMsg("paymentText.totalItems")}</Typography>*/}
            {/*  <Typography>*/}
            {/*    + {currency}*/}
            {/*    0*/}
            {/*  </Typography>*/}
            {/*</Stack>*/}
            {/*<Stack justifyContent={"space-between"}>*/}
            {/*  <Typography>{formatMsg("paymentText.discount")}</Typography>*/}
            {/*  <Typography>*/}
            {/*    - {currency}*/}
            {/*    0*/}
            {/*  </Typography>*/}
            {/*</Stack>*/}
            {/*<Stack justifyContent={"space-between"}>*/}
            {/*  <Typography>{formatMessage("actualPayment")}</Typography>*/}
            {/*  <Typography sx={{ color: "primary.main", fontSize: 16, fontWeight: 700 }}>*/}
            {/*    {currency} 0*/}
            {/*  </Typography>*/}
            {/*</Stack>*/}
            {/* 合同商品下单按钮 */}
            {showFlag == 1 && <Button
              disabled={selectsList.length == 0}
              variant="contained"
              sx={{ display: "block", width: 1, mb: 1, mt: 1 }}
              onClick={handlePaymentFob}
            >
              {formatMsg("btn.CreateContractOrder")}
            </Button>
            }
          </Stack>
          }
          {(loading || contactLoading) && (
            <Box
              sx={{
                textAlign: "center",
                height: "100%",
                position: "absolute",
                width: 1,
                background: "rgba(0, 0, 0, 0.1)",
              }}
            >
              <CircularProgress
                sx={{ position: "absolute", top: "50%", left: "50%", transform: "translateY(-50%);translateX(-50%)" }}
              ></CircularProgress>
            </Box>
          )}
        </Stack>
      </SwipeableDrawer>

      {/* 订购合同 */}
      <ContractAddressDialog {...orderData} close={handleContractClose} />
    </React.Fragment>
  );
};

export default FloatCart;
