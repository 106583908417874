import {
  Container,
  Divider,
  Box,
  Grid,
  Link,
  Paper,
  MenuList,
  MenuItem,
  Popper,
  Stack,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  Typography,
  ListItemIcon,
  useMediaQuery,
} from "@mui/material";
import newWorkConfig from "../../config/network";
import { useNavigate } from "react-router-dom";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveLanguage,
  selectIsLogin,
  selectLanguages,
  selectLogoutModal,
  setLogoutModal,
  setActiveLang,
  selectUserInfo
} from "../../app/appSlice";
import { bindPopper, usePopupState, bindHover } from "material-ui-popup-state/hooks";
import { useFormate } from "../../hooks/useFormate";
import { useLogout } from "../../hooks/logout";
import useBreakPoint from "../../hooks/useBreakPoint";



const Language = () => {
  const dispatch = useDispatch();
  const languages = useSelector(selectLanguages);

  const activeLanguage = useSelector(selectActiveLanguage);
  const popupState = usePopupState({
    variant: "popper",
    popupId: "languageBtn",
  });
  const handleClick = (item) => {
    dispatch(setActiveLang(item.id));
    popupState.close();
  };
  const langItems = useMemo(() => {
    return Object.keys(languages).map((key) => ({ id: key, name: languages[key] }));
  }, [languages]);
  const activeItem = useMemo(() => {
    return langItems.find((o) => o.id === activeLanguage);
  }, [langItems, activeLanguage]);

  // console.log('langItems',activeItem)
  return (
    <React.Fragment>
      <Box
        {...bindHover(popupState)}
        sx={{ background: "#f5f5f5", px: 2, py: 0.5, display: "flex", alginItems: "center" }}
      >
        {activeItem && (
          <img
            width={24}
            height={24}
            alt={activeItem.name}
            style={{ marginRight: 12 }}
            src={`${newWorkConfig.apiDomain}/static/images/${activeItem.id}.svg`}
          ></img>
        )}
        {activeItem?.name || activeLanguage}
      </Box>
      <Popper {...bindPopper(popupState)} placement="bottom" sx={{ zIndex: 999 }}>
        <Paper elevation={2}>
          <MenuList>
            {langItems.map((item, index) => (
              <MenuItem key={(item, index)} onClick={() => handleClick(item)}>
                <ListItemIcon>
                  <img
                    alt={activeItem.name}
                    width={24}
                    height={24}
                    src={`${newWorkConfig.apiDomain}/static/images/${item.id}.svg`}
                  ></img>
                </ListItemIcon>
                <Typography variant="inherit">{item.name}</Typography>
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Popper>
    </React.Fragment>
  );
};

const Header = () => {
  const userInfo = useSelector(selectUserInfo);
  const dispatch = useDispatch();
  const { breakpoints, theme } = useBreakPoint();
  const hideBtnQuery = theme.breakpoints.down(900);
  const hideBtn = useMediaQuery(theme.breakpoints.down(900));
  const { formatMsg } = useFormate();
  const navigate = useNavigate();
  const isLogin = useSelector(selectIsLogin);
  const logout = useLogout();
  const logoutModal = useSelector(selectLogoutModal);
  const handleNav = (path) => {
    navigate(path);
  };
  const toHelpCenter = (pageKey) => {
    navigate(`/help_center${pageKey ? "?pageKey=" + pageKey : ""}`);
  };
  // 官网
  const handleToHomePage = () => {
    // window.open('https://ww.m2bchi.com/#/home')
    window.open('https://www.starhomeone.com/#/home')
  };
  const handleToTenants = () => {
    let path = '/merchantSettlement';
    navigate(path);
  };

  const handleLogout = () => {
    dispatch(setLogoutModal(false));
    logout();
  };
  const logoutModalFunc = (boolean = false) => {
    dispatch(setLogoutModal(boolean));
  };
  return (
    <Box className="components-header" sx={{ background: "#eaeaea" }}>
      <Container disableGutters={hideBtn}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ minHeight: "40px", [hideBtnQuery]: { minHeight: 20 } }}
        >
          <Grid item xs={12} xl="auto" lg="auto" md="auto" sx={{ [hideBtnQuery]: { width: "100%", flex: "0 0 100%" } }}>
            <Box
              sx={{
                color: "#7b7b7b",
                fontSize: 14,
                position: "relative",
                width: "100%",
                py: 0.5,
                height: 24,
                overflow: "hidden",
                ":hover": {
                  ".MuiTypography-root": {
                    animationPlayState: "paused",
                  },
                },
              }}
            >
              <Typography
                variant="body2"
                component="span"
                sx={{
                  color: "#7b7b7b",
                  fontSize: 14,
                  [hideBtnQuery]: {
                    position: "absolute",
                    animation: "slide 10s infinite linear",
                    whiteSpace: "nowrap",
                  },
                }}
              >
                {formatMsg({ id: "header.welcome", defaultMessage: "Welcome to the furniture wholesale platform!" })}
              </Typography>
            </Box>
          </Grid>
          {!hideBtn && (
            <Grid item xs={12} xl="auto" lg="auto" md="auto">
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                sx={{
                  [theme.breakpoints.between("xs", "md")]: {
                    justifyContent: "center",
                  },
                }}
              >
                {isLogin & userInfo.certified_status !== 7 && (
                  <Box
                    sx={{ color: "#fe3737", fontSize: "14px", cursor: "pointer" }}
                  >
                    ({formatMsg({ id: "certification" })})
                  </Box>
                )}
                {isLogin && (
                  <Link
                    underline="none"
                    onClick={() => handleNav("/user_center")}
                    sx={{ color: "#fe3737", cursor: "pointer", ":hover": { color: "primary.main" } }}
                  >
                    Hello! {userInfo.first_name+userInfo.last_name || userInfo.username} 
                    
                  </Link>
                )}
                {isLogin && (
                  <Link
                    underline="none"
                    onClick={() => logoutModalFunc(true)}
                    sx={{ color: "#7b7b7b", cursor: "pointer", ":hover": { color: "primary.main" } }}
                  >
                    {formatMsg({ id: "logout", defaultMessage: "logout" })}
                  </Link>
                )}
                {!isLogin && (
                  <Link
                    underline="none"
                    onClick={() => handleNav("/login")}
                    sx={{ color: "#fe3737", cursor: "pointer", ":hover": { color: "primary.main" } }}
                  >
                    {formatMsg({ id: "login", defaultMessage: "Login" })}
                    
                  </Link>
                )}
                {!isLogin && (
                  <Link
                    underline="none"
                    onClick={() => handleNav("/register")}
                    sx={{ color: "#7b7b7b", cursor: "pointer", ":hover": { color: "primary.main" } }}
                  >
                    {formatMsg({ id: "register", defaultMessage: "Register" })}
                    
                  </Link>
                )}
                {/* 商家入驻 */}
                <Link
                  underline="none"
                  onClick={() => handleToTenants()}
                  sx={{ color: "#7b7b7b", cursor: "pointer", ":hover": { color: "primary.main" } }}
                >
                  {formatMsg("header.tenants")}
                </Link>
                 
                <Link
                  underline="none"
                  onClick={() => toHelpCenter()}
                  sx={{ color: "#7b7b7b", cursor: "pointer", ":hover": { color: "primary.main" } }}
                >
                  {formatMsg("header.helpCenter")}
                </Link>
                <Link
                  underline="none"
                  onClick={() => toHelpCenter("contact_us")}
                  sx={{ color: "#7b7b7b", cursor: "pointer", ":hover": { color: "primary.main" } }}
                >
                  {formatMsg("header.contactUs")}
                </Link>

                {/* 官网首页 */}
                <Link
                  underline="none"
                  onClick={() => handleToHomePage()}
                  sx={{ color: "#7b7b7b", cursor: "pointer", ":hover": { color: "primary.main" } }}
                >
                  {formatMsg("header.HomePage")}
                </Link>
                {!breakpoints.xs && <Language></Language>}
              </Stack>
            </Grid>
          )}
        </Grid>
      </Container>
      <Dialog open={logoutModal} sx={{ height: 300 }}>
        <DialogTitle>{formatMsg({ id: "warning" })}</DialogTitle>
        <DialogContent sx={{ width: 300 }}>{formatMsg("tips.logout")}</DialogContent>
        <DialogActions>
          <Button size="small" onClick={handleLogout}>
            {formatMsg({ id: "logout", defaultMessage: "Logout" })}
          </Button>
          <Button size="small" onClick={() => logoutModalFunc(false)}>
            {formatMsg("btn.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Header;
