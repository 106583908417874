import { useDispatch } from "react-redux";
import { loginOut, setMessageInfo } from "../app/appSlice";
import { useNavigate } from "react-router-dom";
import { useFormate } from "./useFormate";
export const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMsg } = useFormate();
  const logout = () => {
    dispatch(loginOut());
    dispatch(setMessageInfo(formatMsg("tips.logoutSuccess")));
    navigate("/login");
  };
  return logout;
};
