import { Box, CircularProgress } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setMessageInfo } from "../../app/appSlice";
import { useIntl } from "react-intl";
import { uploadFile } from "./api";
import React, { useState, useRef } from "react";
const UploadBox = ({ sx, children, value, onUploadSuccess = () => {}, ...props }) => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const handleUpload = () => {
    if (loading) return;
    uploadRef.current.click();
  };
  const handleFileChange = (e) => {
    let file = e.target.files?.[0];
    if (!file) return;
    setLoading(true);
    uploadFile(file)
      .then((data) => onUploadSuccess(data))
      .catch((e) => {
        dispatch(setMessageInfo({ message: e || formatMessage({ id: "uploadFailed" }) }));
      })
      .finally(() => setLoading(false));
  };
  return (
    <React.Fragment>
      <input
        type="file"
        ref={uploadRef}
        style={{ display: "none" }}
        onClick={(e) => (e.target.value = null)}
        onChange={handleFileChange}
      />
      <Box
        sx={{
          background: "#e8e8e8",
          borderRadius: 2,
          width: 120,
          height: 120,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          ...sx,
        }}
        onClick={handleUpload}
      >
        {loading && <CircularProgress></CircularProgress>}
        {!loading && !value && !children && <Add fontSize="large"></Add>}
        {!loading && value && !children && (
          <img alt="" src={value} style={{ maxWidth: "100%", maxHeight: "100%" }}></img>
        )}
        {!loading && children && <children></children>}
      </Box>
    </React.Fragment>
  );
};
export default UploadBox;
