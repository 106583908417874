import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";

const useBreakPoint = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const md = useMediaQuery(theme.breakpoints.only("md"));
  const lg = useMediaQuery(theme.breakpoints.only("lg"));
  const xl = useMediaQuery(theme.breakpoints.only("xl"));
  const breakpoints = useMemo(() => {
    return { xs, sm, md, lg, xl };
  }, [xs, sm, md, lg, xl]);
  const bPointesChcek = (arr) => {
    if (!arr) {
      console.warn("请传入正确的判断范围");
      return;
    }
    return arr.findIndex((o) => breakpoints[o] === true) >= 0;
  };
  return { breakpoints, theme, bPointesChcek };
};

export default useBreakPoint;
