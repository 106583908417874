import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchMenu } from "./menuApi";

const initialState = {
  menuSource: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchMenuAsync = createAsyncThunk("menu/fetchMenu", async (amount) => {
  const response = await fetchMenu();
  // The value we return becomes the `fulfilled` action payload
  return response.data;
});

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchMenuAsync.fulfilled, (state, action) => {
      state.menuSource = action.payload;
    });
  },
});

export const selectMenuSource = (state) => state.menu.menuSource;

export default menuSlice.reducer;
