import React, { useState, memo, useEffect, useCallback, useImperativeHandle, forwardRef, useMemo } from "react";
import {
  // Typography,
  // Stack,
  Box,
  // Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Pagination,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Backdrop,
  Modal
} from "@mui/material";
import {useFormate} from "../../hooks/useFormate";
import Empty from "../Empty";
import {  setMessageInfo } from "../../app/appSlice";

import { useDispatch } from "react-redux";

import closeIcon from "../../assets/img/close.png";
import './index.scss';
import { getAddressList, saveAddress as apiSaveAddress } from './api'

/**
 * @name AddressBookDialog
 * @param handleData 点击选择时回传的数据
 * ***
 * 绑定ref成功后携带的方法
 * @function openDialog  打开地址簿对话框
 * @function saveAddress  保存地址
 */
const AddressBookDialog = forwardRef(({ handleData }, ref) => {
  const dispatch = useDispatch()
  const {formatMessage, formatMsg} = useFormate('inventory');
  const [openModal, setOpenModal] = useState(false)
  
  const [total, setTotal] = useState(0);
  const [pageTotal, setPageTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [tableList, setTableList] = useState([])

  // const [showList, setShowList] = useState([])
  const [paramData, setParamData] = useState({
      page: 1,
      limit: 10,
  })

  useImperativeHandle(ref, () => ({ // 暴露给父组件的方法
    openDialog,
    saveAddress
 }))

  const mockData = () => {
    let tableData = []
    const mockTotal = 38
    // 创建数据
    for(let i = 0; i < mockTotal; i++) {
      tableData.push({
        "receiving_company": "名称" + i, 
        "receiving_address": "地址" + i, 
        "receiving_zip": "邮编" + i, 
        "receiving_phone": "电话" + i, 
        "add_time": "2024-04-01 11:00:59", 
        "id": i + 1 
      })
    }
    setTableList(tableData)
    setTotal(mockTotal)
    setPageTotal(Math.ceil(mockTotal / paramData.limit))
    
  }

  const showList = useMemo(() => {
    // console.log(paramData)
    // console.log(tableList)
    // 根据每页页数，展示多少条数据
    let finalData = [...tableList]
    // console.log(finalData)
    return finalData.splice((paramData.page - 1) * paramData.limit, paramData.limit)
  }, [paramData, total, open])

  // 保存地址
  const saveAddress = (data) => {
    data && function() {
        let str = '' // 标记提示文案
        for(let key in data) {
            if(str) { // 提示文案存在时
                continue;
            }
            !str && ( // 没有提示文案的判断
                !data[key] && function() {
                    // 精准判断缺少什么值并赋值相应的值到提示文案
                    switch(key){
                        case 'receiving_company': 
                            str = 'addressBook.name'
                            break;
                        case 'receiving_address': 
                            str = 'addressBook.address'
                                break;
                        case 'receiving_zip': 
                            str = 'addressBook.zipCode'
                            break;
                        case 'receiving_phone': 
                            str = 'addressBook.phone'
                            break;
                    }
                }()
            )
        }
        // 判断提示文案 ？ 有则提示错误信息: 没有则提交保存
        str ? dispatch(setMessageInfo({ type: "warning", message: formatMsg("form.inputEmpty", { text: formatMsg(str) }) })) : apiSaveAddress({
            ...data,
            uuid: localStorage.getItem('user_uuid')
        }).then(res => {
            if(res.code == 200) {
              dispatch(setMessageInfo({ type: "success", message: formatMsg("tips.success") }));
            }
        })
    }()

  }

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);
  const closeDialog = () => {
    setOpen(false);
  };
  // 切换页码的逻辑处理
  const handleChangePage = (event, newPage) => {
    // console.log(event)
    if(paramData.page == newPage) return
    setPageNo(newPage)
    // fetch(pageData);
  }
  // 设置页码
  const setPageNo = (page) => {
    const pageData = {...paramData}
    pageData.page = page
    setParamData(pageData)
  }

  // 选择地址时的处理
  const selectAddress = (data) => {
    // console.log(data)
    handleData(data)
    closeDialog()
  }

  const fetch = () => {
    let data ={
        uuid: localStorage.getItem('user_uuid')
    }
    getAddressList(data).then(res => {
      // console.log(res)
      
      setTableList(res.data)
      setTotal(res.data.length)
      setPageTotal(Math.ceil(res.data.length / paramData.limit))
    })
  }

  useEffect(() => {
    if(open) {
      // console.log('open')
      // mockData()
      setPageNo(1)
      fetch()
    }
  }, [open])

  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogTitle className="dialog_title">
          {formatMsg('addressBook.title')}
          <Box className="fobOrder_css" onClick={() => closeDialog()}>
            {" "}
            <img src={closeIcon} className="fobOrderIcon" />
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            width: "960px",
            height: "640px",
            padding: " 0 15px",
            paddingTop: "10px !important",
            paddingLeft: "8px",
          }}
        >
          <Box sx={{ marginTop: "10px", marginBottom: "20px" }}>
            <TableContainer component={Paper}>
              <Table size="small" className="tbale_css"  aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{formatMsg('addressBook.no')}</TableCell>
                    <TableCell align="center">{formatMsg('addressBook.name')}</TableCell>
                    <TableCell align="center">{formatMsg('addressBook.phone')}</TableCell>
                    <TableCell align="center">{formatMsg('addressBook.zipCode')}</TableCell>
                    <TableCell align="center">{formatMsg('addressBook.address')}</TableCell>
                    <TableCell align="center">{formatMsg('addressBook.operation')}</TableCell>
                    
                  </TableRow>
                </TableHead>
                {showList.length > 0 && (
                  <TableBody>
                    {showList.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row" align="center">
                          {row.id}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.receiving_company}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.receiving_phone}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.receiving_zip}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.receiving_address}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => {selectAddress(row)}}
                            >
                                {formatMsg('addressBook.select')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            {showList.length == 0 && !loading &&
              <Box sx={{py: 4, textAlign: "center", mx: "auto"}}>
                  <Empty text='contract.pursaleNoData'></Empty>
              </Box>
            }
            {/* 分页组件 */}
            {showList.length > 0 &&
              <Box className="pagination">
                  <Button onClick={(e) => {handleChangePage(e, 1)}}>{formatMsg('addressBook.fitsPage')}</Button>
                  <Pagination count={pageTotal} page={paramData.page} color="primary" size="small" variant="outlined" shape="rounded" onChange={handleChangePage}
                  hideNextButton={true} hidePrevButton={true}
                  />
                  <Button onClick={(e) => {handleChangePage(e, pageTotal)}}>{formatMsg('addressBook.lastPage')}</Button>
              </Box>
            }
          </Box>
        </DialogContent>
      </Dialog>

    </React.Fragment>
  );
});
export default memo(AddressBookDialog);
