import request, { getParams } from "../../utils/request";
export const uploadFile = (file) => {
  const params = getParams({ file_info: file });
  let formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });
  return request.post("/system/common/file/upload", formData, {
    headers: { "content-type": "multipart/form-data" },
  });
};
