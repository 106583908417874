import React, { useMemo, useState, useRef, useEffect, memo, useCallback } from "react";
import {
    // Typography,
    // Stack,
    Box,
    // Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    // IconButton,
    TextField,
    FormControlLabel,
    RadioGroup,
    Radio,
    // Autocomplete,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Modal,
} from "@mui/material";

import { DatePicker } from '../../../components/DatePicker'
import { useFormate } from "../../../hooks/useFormate";
import { useDispatch } from "react-redux";
import { setMessageInfo } from "../../../app/appSlice";
import { useLayout } from "./theme";
import { validInteger, validMoney } from '../../../utils/validate'

import {
 SubmitOrderContract, getContractList, getContractSn
} from './api';
import './style/contractAddress.scss';

import closeIcon from '../../../assets/img/close.png';

import UploadBox from "../../../components/UploadBox";

import { useFormData, useFormCheck, } from "../../../hooks/useFormCheck";
// 导入地址簿dialog
import AddressBookDialog from "../../../components/AddressBookDialog";

// 详情订单数据

const TextFields = ({ name, formData = {}, handleValChange, clearStatus, errorStatus, ...props }) => {
    const { formatMessage } = useFormate("information");
    const label = formatMessage(name);
    const inputRef = useRef();
    useEffect(() => {
        if (inputRef.current && formData[name] !== inputRef.current.value) {
            inputRef.current.value = formData[name] || "";
        }
    }, [formData]);

    return (
        <Box>
            <TextField
                inputRef={inputRef}
                helperText={""}
                variant="standard"
                fullWidth
                size="small"
                name={name}
                placeholder={label}
                onChange={() => clearStatus(name)}
                onBlur={(e) => handleValChange({ [name]: e.target.value.trim() })}
                FormHelperTextProps={{ sx: { opacity: errorStatus[name] ? 1 : 0 } }}
                {...errorStatus[name]}
                {...props}
            >
            </TextField>

        </Box>
    );
};
const TextFieldFOb = ({ name, disabled, formData = {}, handleValChange, clearStatus, errorStatus, ...props }) => {
    const { formatMessage } = useFormate("dig");
    const label = formatMessage(name);

    const inputRef = useRef();
    useEffect(() => {
        if (inputRef.current && formData[name] !== inputRef.current.value) {
            inputRef.current.value = formData[name] || "";
        }
    }, [formData]);
    return (
        <TextField
            inputRef={inputRef}
            // label={label}
            helperText={` `}
            variant="outlined"
            fullWidth
            size="small"
            name={name}
            placeholder={label}
            onChange={() => clearStatus(name)}
            onBlur={(e) => handleValChange({ [name]: e.target.value.trim() })}
            FormHelperTextProps={{ sx: { opacity: errorStatus[name] ? 1 : 0 } }}
            {...errorStatus[name]}
            {...props}

        ></TextField>
    );
};

 const ContractAddressDialog = memo(function ContractAddressDialog({ open, close, merchant_id, merchant_uuid,select_ids })  {
    
    const addressBook = useRef()
     const { formatMessage, formatMsg } = useFormate("orderDetails");
     const dispatch = useDispatch();
     const { formCheck, errorStatus, handleClearStatus } = useFormCheck();
     const [selectContact, setSelectContact] = useState('beforeDelivery');
     const { handleFormDataChange, formData, setFormData } = useFormData();
     const [regionOpts, setRegionOpts] = useState([]);
     const { isLayout, layoutQuery } = useLayout();
     // 定义一个总数量
     const [goodsTotalNumber, setGoodsTotalNumber] = useState(0)
     // 总金额
     const [goodsTotalPrice, setGoodsTotalPrice] = useState(0)
     const [spareMoney, setSpareMoney] = useState(0)
     const [operateType, setOperateType] = useState(1)
     // 定金计算
     const [integerFlage, setIntegerFlage] = useState(false)
     const [disabledFlag, setDisabledFlag] = useState(true);
     const [fobAdressFlag, setFobAdressFlag] = useState(false);
     const [contractSn, setContractSn] = useState('-');
     const [tableList, setTableList] = useState([
         {
             imgSrc: '',
             custom_sn: '',
             quality: '',
             size: "",
             packing: '',
             price: '',
             color: '',
             category: '',
             volume: '',
             // 新的字段
             image_id: '',
         }
     ]);
     // 用户确认状态
     const [userVerifyStatus, setUserVerifyStatus] = useState(0)
     // 商家确认状态
     const [merchantVerifyStatus, setMerchantVerifyStatus] = useState(0)
     // 三个选择状态
     const methodKeys = [
         { key: "undamage", name: "undamage" },
         { key: "damage", name: "damage" },
         { key: "missing", name: "missing" },
     ];
     // 添加商品
     const handleAddGoods = () => {
         let addForm = {
             imgSrc: '',
             custom_sn: '',
             quality: '',
             size: "",
             packing: '',
             price: '',
             color: '',
             category: '',
             volume: '',
             // 新的字段
             image_id: '',
         }
         const newList = [...tableList]
         newList.push(addForm)
         setTableList(newList)
     }

     // 订购合同选择状态
     const orderMethodKeys = [
         { key: "beforeDelivery", name: "beforeDelivery" },
         { key: "afterDelivery", name: "afterDelivery" },
         { key: "uponArrival", name: "uponArrival" },
     ];
     const handleValChangedData = (value, item, list) => {
         item.imgSrc = value.goodsImage.file_path
         item.image_id = value.goodsImage.file_id
         setTableList([...tableList]);
     }
     // const fetchProvince = (parent_id) => {
     //     getRegion({ parent_id }).then((data) => {
     //         setRegionOpts(data.list);
     //     });
     // };
     const handleValChange = (data = {}) => {
         let key = Object.keys(data)[0];
         let tempData = { ...formData, ...data };
         // if (key === "country") {
         //     tempData.province = undefined;
         //     if (data[key]?.id) {
         //         fetchProvince(data[key].id);
         //     }
         // }
         handleFormDataChange(tempData);
     };
     const baseFieldProps = {
         handleValChange,
         errorStatus,
         formData,
         clearStatus: handleClearStatus,
     };
     const handleSelectOrderContract = (e) => {
         setSelectContact(e)
         let dataContract = { ...formData }
         // console.log('选择的数据',dataContract)
         if (e == 'beforeDelivery') {
             dataContract.balance_type = 1
             setDisabledFlag(true)
         } else if (e == 'afterDelivery') {
             dataContract.balance_type = 2
             setDisabledFlag(true)
         } else if (e == 'uponArrival') {
             dataContract.balance_type = 3
             setDisabledFlag(false)
         }
         setFormData(dataContract);
     }
     const handleDelGoods = (index) => {
         const updateAry = tableList.filter((_, i) => i !== index)
         let newTotalQuality = 0
         // 输入的时候处理价格显示
         let newTotalPrice = 0
         for (let i = 0; i < updateAry.length; i++) {
             newTotalPrice += (Number(updateAry[i].price) * Number(updateAry[i].quality))
             newTotalQuality += Number(updateAry[i].quality)
         }
         setGoodsTotalNumber(newTotalQuality)
         setGoodsTotalPrice(newTotalPrice)
         setTableList(updateAry)
     }

     // 统一处理商品内容的输入
     const handleValChangeInput = (value, index, key) => {
         const updateTableList = [...tableList];
         updateTableList[index] = {
             ...updateTableList[index],
             [key]: value
         }
         // 输入的时候处理数量显示
         let newTotalQuality = 0
         // 输入的时候处理价格显示
         let newTotalPrice = 0
         if (key == 'quality') {
             for (let i = 0; i < updateTableList.length; i++) {
                 newTotalQuality += Number(updateTableList[i].quality)
                 newTotalPrice += (Number(updateTableList[i].price) * Number(updateTableList[i].quality))
             }
             setGoodsTotalNumber(newTotalQuality)
             setGoodsTotalPrice(newTotalPrice)
         }
         if (key == 'price') {
             for (let i = 0; i < updateTableList.length; i++) {
                 newTotalPrice += (Number(updateTableList[i].price) * Number(updateTableList[i].quality))
             }
             setGoodsTotalPrice(newTotalPrice)
         }
         setTableList(updateTableList)
     }
     //统一处理成功提交后数据清空
     const clearAllData = useCallback(() => {
        setTableList([
            {
                imgSrc: '',
                custom_sn: '',
                quality: '',
                size: "",
                packing: '',
                price: '',
                color: '',
                category: '',
                volume: '',
                // 新的字段
                image_id: '',
            }
        ])
        setSpareMoney(0)
        setFormData({})
        setGoodsTotalPrice(0)
        setGoodsTotalNumber(0)
     }, [])

     //处理弹窗关闭
     const  handleDialogClose = () => {
        clearAllData()
        close && close()
     }

     const handleVerify = (SubmitType) => {
        const cloneTableList = JSON.parse(JSON.stringify(tableList))
        if (cloneTableList.length === 0) { //不存在数据
            return 
        }
        //校验用户输入的数据 数量以及金额
        if (!cloneTableList.every(item => validInteger(item.quality) && validMoney(item.price))) {
            //验证每个数据的数量以及价格是否合法
            return dispatch(setMessageInfo({ type: "error", message: formatMsg("dig.EnterLegalValue") }));
        }
       //获取删除的id
       const del_ids = cloneTableList.map(item => item?.id ?? 0).filter(item => item !== 0).join(',')
        //转换金额以及剔除无用字段
        for (let a = 0; a < cloneTableList.length; a++) {
            cloneTableList[a].price = Number(cloneTableList[a].price) * 100
            delete cloneTableList[a]['contract_sn']
            delete cloneTableList[a]['image_path']
            delete cloneTableList[a]['imgSrc']
            delete cloneTableList[a]['purchase_contract_id']
            delete cloneTableList[a]['id'] //购物车id
        }
         //判断数组中是否有相同的值
         let postData = {
             user_uuid: localStorage.getItem('user_uuid'),
             del_ids,
             merchant_uuid,
             is_contract: 1, //是否是合同商品 清除数据
             save_type: SubmitType,
             operator_type: operateType,
             maker_name: formData.ManufacturerName,
             maker_address: formData.ManufacturerAddress,
             maker_zip: formData.ManufacturerPostcode,
             maker_phone: formData.ManufacturerTelephone,
             purchaser_name: formData.PurchaserName,
             purchaser_address: formData.PurchaserAddress,
             purchaser_zip: formData.PurchaserPostcode,
             purchaser_phone: formData.PurchaserTelephone,
             delivery_date: formData.DeliveryDate?.format("YYYY-MM-DD"),
             receiving_date: formData.receiptDate?.format("YYYY-MM-DD"),
             pay_action: formData.PaymentMethod,
             total_money: goodsTotalPrice * 100,  //做处理
             deposit_ratio: formData.deposit,
             goods_standard: formData.goods_standard,
             balance_type: formData.balance_type == undefined ? 1 : formData.balance_type,
             arrival_day_pay: formData.day == undefined ? '' : formData.day,
             goods_json: JSON.stringify(cloneTableList),
         }
         // console.log(postData)
         let getFormData = { ...formData }
         SubmitOrderContract(postData).then((res) => {
             if (res) {
                 // 订购合同创建成功
                 dispatch(setMessageInfo({ type: "success", message: formatMsg("dig.submitSuccess") }));
                //  setFobAdressFlag(false)
                 getFormData.id = res.id //这个我不知道干嘛的
                 clearAllData() //清除所有数据
                 // 外界会根据action进行表示是否清除选中数据
                 close && close('clear') 
             }
         })
     }
     const handleValueDeposit = (e) => {
         // 判断输入类型
         let numberType = e.includes('.')
         console.log('输入的定金金额',numberType)
         let newSpareMoney = 0
         if (!numberType) {
             // setIntegerFlage(false)
             if (goodsTotalPrice !== 0) {
                 newSpareMoney = Number(goodsTotalPrice) * (1 - Number(e / 100))
                 setSpareMoney(newSpareMoney.toFixed(2))
             }
         } else {
             dispatch(setMessageInfo({ type: "warning", message: formatMsg("pay.integer") }));
             // setIntegerFlage(true)
         }

     }
     
    //获取合同数据
    const fetchContractSn = async () => {
        try {
            const snRes = await getContractSn({ merchant_uuid })
            setContractSn(snRes.data)
        } catch (error) {
            console.error('获取合同编号', error)
        }
    }
     //获取数据
    const fetchData = async () =>  {
        try {
            let data = { mch_id: merchant_id }
            //选择指定商品进行查找 无需根据商户id查询
            if (Array.isArray(select_ids) && select_ids.length > 0) {
                data['ids'] = select_ids.join(',')
                delete data['mch_id']
            }
            const res = await getContractList(data)
            console.log({res})
            //将数据进行转换显示内容
            const covertAry = res?.list.map(item => ({
                id: item.id,
                imgSrc: item.preview,
                image_id: item.preview_id, //图片id
                custom_sn: item.custom_sn,
                quality: item.num, //数量
                size: '',
                packing: '',
                price: '',
                color: '',
                category: '',
                volume: '',
            }))
            //对数据进行赋值
            setTableList(covertAry)
            //数量判断
            setGoodsTotalNumber(covertAry.reduce((prev, cur) => (prev += cur.quality),0))
        } catch (error) {
            console.error('获取商品列表发生错误', error)
        }
    }
    // 打开地址簿
    const openAddressBook = () => {
        if(addressBook.current) {
            addressBook.current.openDialog()
        }
    }
    // 接收地址簿回传的数据
    const setCgAddress = (data) => {
        let result = {}
        result.PurchaserAddress = data.receiving_address
        result.PurchaserName = data.receiving_company
        result.PurchaserPostcode = data.receiving_zip
        result.PurchaserTelephone = data.receiving_phone
        handleFormDataChange(result)
    }

    // 保存地址
    const saveAddress = () => {
        // console.log('保存')
        const data = baseFieldProps.formData
        addressBook.current.saveAddress({
            receiving_company: data.PurchaserName,
            receiving_address: data.PurchaserAddress,
            receiving_zip: data.PurchaserPostcode,
            receiving_phone: data.PurchaserTelephone
        })

    }


     useEffect(() => {
        if (open) {
            fetchContractSn()
            fetchData()
        }
     }, [open])
     return (
        <React.Fragment>
            {/* fob地址弹窗 */}
            <Dialog open={open}>
                {/* 站内信窗口 */}
                <DialogTitle sx={{ fontWeight: 600, textAlign: "center", background: "#ffffff", fontSize: "20px", paddingBottom: "0px !important" }}>
                    {formatMsg("fobOrderAddress")}
                    <Box className="fobOrder_css" onClick={() => handleDialogClose()}> <img src={closeIcon} className='fobOrderIcon' /></Box>
                </DialogTitle>
                <DialogContent sx={{ width: "1400px", height: "1000px", padding: " 0 15px", paddingTop: "10px !important", paddingLeft: "8px" }}>
                    {/* 内容   */}
                    <Box
                        sx={{ padding: "0 10px" }}
                    >
                        <Box sx={{ marginBottom: "5px", fontWeight: 600, fontSize: "15px" }}> {formatMsg("orderList.filter.contractNumber")}:<span style={{ color: "blue", fontWeight: "500", paddingLeft: "2px" }}>{contractSn}</span></Box>
                        {/* 地址表单 */}
                        <Box className="contract_box">
                            <Box className="cg_box">
                                <Box className="cg_title">
                                    <Box component="span">{formatMsg("dig.purchaser")}</Box>
                                    <Box className="cg_btns">
                                        <Button onClick={openAddressBook}>{formatMsg('addressBook.title')}</Button>
                                        <Button onClick={saveAddress}>{formatMsg('addressBook.save')}</Button>
                                    </Box>
                                </Box>
                                <Box className="content_box">
                                    <Box className="content_item">{formatMsg("dig.PurchaserName")}</Box>
                                    <Box className="content_input">
                                        <TextFieldFOb name="PurchaserName" {...baseFieldProps} sx={{ paddingLeft: "0px" }}></TextFieldFOb>
                                    </Box>
                                </Box>
                                <Box className="content_box">
                                    <Box className="content_item">{formatMsg("dig.PurchaserAddress")}</Box>
                                    <Box className="content_input">
                                        <TextFieldFOb name="PurchaserAddress"  {...baseFieldProps} sx={{ paddingLeft: "0px" }}></TextFieldFOb>
                                    </Box>
                                </Box>
                                <Box className="content_box">
                                    <Box className="content_item">{formatMsg("dig.PurchaserPostcode")}</Box>
                                    <Box className="content_input">
                                        <TextFieldFOb name="PurchaserPostcode"  {...baseFieldProps} sx={{ paddingLeft: "0px" }}></TextFieldFOb>
                                    </Box>
                                </Box> <Box className="content_box">
                                <Box className="content_item">{formatMsg("dig.PurchaserTelephone")}</Box>
                                <Box className="content_input">
                                    <TextFieldFOb name="PurchaserTelephone"  {...baseFieldProps} sx={{ paddingLeft: "0px" }}></TextFieldFOb>
                                </Box>
                            </Box>
                            </Box>

                            <Box className="zz_box">
                                <Box className="zz_title">{formatMsg("dig.manufacturer")}</Box>
                                <Box className="content_box">
                                    <Box className="content_item">{formatMsg("dig.ManufacturerName")}</Box>
                                    <Box className="content_input">
                                        <TextFieldFOb name="ManufacturerName" {...baseFieldProps} sx={{ paddingLeft: "0px" }}></TextFieldFOb>
                                    </Box>
                                </Box>
                                <Box className="content_box">
                                    <Box className="content_item">{formatMsg("dig.ManufacturerAddress")}</Box>
                                    <Box className="content_input">
                                        <TextFieldFOb name="ManufacturerAddress"  {...baseFieldProps} sx={{ paddingLeft: "0px" }}></TextFieldFOb>
                                    </Box>
                                </Box>
                                <Box className="content_box">
                                    <Box className="content_item">{formatMsg("dig.ManufacturerPostcode")}</Box>
                                    <Box className="content_input">
                                        <TextFieldFOb name="ManufacturerPostcode"  {...baseFieldProps} sx={{ paddingLeft: "0px" }}></TextFieldFOb>
                                    </Box>
                                </Box> <Box className="content_box">
                                <Box className="content_item">{formatMsg("dig.ManufacturerTelephone")}</Box>
                                <Box className="content_input">
                                    <TextFieldFOb name="ManufacturerTelephone"  {...baseFieldProps} sx={{ paddingLeft: "0px" }}></TextFieldFOb>
                                </Box>
                            </Box>
                            </Box>
                        </Box>
                        {/* 协定的收货地址 */}
                        <Box className="agreement_box">
                            <Box className="agreement_title">
                                {formatMsg("dig.agreementTitle")}
                            </Box>
                            {/* 收货地址表单 */}
                            <Box className="agreement_address">
                                <Grid container spacing={2} marginBottom={2}>
                                    <Grid item {...(isLayout ? { xs: 6 } : { xs: 6 })} sx={{ display: "flex", }}>
                                        {/* 交货日期选择 */}
                                        <Box className="item_box_grid" >
                                            {formatMsg("dig.DeliveryDate")}
                                        </Box>
                                        <Box sx={{
                                            maxWidth: 500,
                                            minWidth: 300,
                                            ".SingleDatePicker": { width: "100%" },
                                            ".SingleDatePickerInput": {
                                                width: "100%",
                                                display: "flex",
                                                ".DateInput__small": { flex: 1 },
                                            },
                                        }}>
                                            <DatePicker numberOfMonths={1} date={formData.DeliveryDate} onChange={(date) => handleFormDataChange({ DeliveryDate: date })}></DatePicker>
                                        </Box>
                                        {/* <TextFieldFOb name="DeliveryDate" required {...baseFieldProps}></TextFieldFOb> */}
                                    </Grid>
                                    <Grid item {...(isLayout ? { xs: 6 } : { xs: 6 })} sx={{ display: "flex", }}>
                                        {/* 收货日期 */}
                                        <Box className="item_box_grid" >
                                            {formatMsg("dig.receiptDate")}
                                        </Box>
                                        <Box sx={{
                                            maxWidth: 500,
                                            minWidth: 300,
                                            ".SingleDatePicker": { width: "100%" },
                                            ".SingleDatePickerInput": {
                                                width: "100%",
                                                display: "flex",
                                                ".DateInput__small": { flex: 1 },
                                            },
                                        }}>
                                            <DatePicker numberOfMonths={1} date={formData.receiptDate} onChange={(date) => handleFormDataChange({ receiptDate: date })}></DatePicker>
                                        </Box>
                                        {/* <TextFieldFOb name="receiptDate" required {...baseFieldProps}></TextFieldFOb> */}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>

                                    <Grid item {...(isLayout ? { xs: 6 } : { xs: 6 })} sx={{ display: "flex", }}>
                                        <Box className="item_box_grid" >
                                            {formatMsg("dig.PaymentMethod")}
                                        </Box>
                                        <TextFieldFOb name="PaymentMethod" required {...baseFieldProps}></TextFieldFOb>
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* 下面的table */}
                            <Box className="table_box">
                                <TableContainer component={Paper}>
                                    <Table aria-label="spanning table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="content_css">{formatMsg("dig.Image")}</TableCell>
                                                <TableCell className="content_css">{formatMsg("dig.ModelNumber")}</TableCell>
                                                <TableCell className="content_css">{formatMsg("dig.Number")}</TableCell>
                                                <TableCell className="content_css">{formatMsg("dig.Dimension")}</TableCell>
                                                <TableCell className="content_css">{formatMsg("dig.PackingMethod")}</TableCell>
                                                <TableCell className="content_css">{formatMsg("dig.Price")}</TableCell>
                                                <TableCell className="content_css">{formatMsg("dig.Color")}</TableCell>
                                                <TableCell className="content_css">{formatMsg("dig.Category")}</TableCell>
                                                <TableCell className="content_css">{formatMsg("dig.Volume")}</TableCell>
                                                <TableCell className="content_css">{formatMsg("Controls")}</TableCell>
                                                
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {tableList.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell className="content_css">
                                                        <Box className="content_image_box">
                                                            <Box className="content_image">
                                                                {/* 图片上传 */}
                                                                <UploadBox
                                                                    value={item.imgSrc}
                                                                    onUploadSuccess={(data) => handleValChangedData({ goodsImage: data }, item, tableList)}
                                                                ></UploadBox>
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className="content_css"><TextField value={item.custom_sn} size="small" placeholder={formatMsg("dig.ModelNumber")} onChange={(e) => handleValChangeInput(e.target.value.trim(), index, 'custom_sn')}></TextField></TableCell>
                                                    <TableCell className="content_css"><TextField value={item.quality} size="small" placeholder={formatMsg("dig.Number")} onChange={(e) => handleValChangeInput(e.target.value.trim(), index, 'quality')}></TextField></TableCell>
                                                    <TableCell className="content_css"><TextField value={item.size} size="small" placeholder={formatMsg("dig.Dimension")} onChange={(e) => handleValChangeInput(e.target.value.trim(), index, 'size')}></TextField></TableCell>
                                                    <TableCell className="content_css"><TextField value={item.packing} size="small" placeholder={formatMsg("dig.PackingMethod")} onChange={(e) => handleValChangeInput(e.target.value.trim(), index, 'packing')}></TextField></TableCell>
                                                    <TableCell className="content_css"><TextField value={item.price} size="small" placeholder={formatMsg("dig.Price")} onChange={(e) => handleValChangeInput(e.target.value.trim(), index, 'price')}></TextField></TableCell>
                                                    <TableCell className="content_css"><TextField value={item.color} size="small" placeholder={formatMsg("dig.Color")} onChange={(e) => handleValChangeInput(e.target.value.trim(), index, 'color')}></TextField></TableCell>
                                                    <TableCell className="content_css"><TextField value={item.category} size="small" placeholder={formatMsg("dig.Category")} onChange={(e) => handleValChangeInput(e.target.value.trim(), index, 'category')}></TextField></TableCell>
                                                    <TableCell className="content_css"><TextField value={item.volume} size="small" placeholder={formatMsg("dig.Volume")} onChange={(e) => handleValChangeInput(e.target.value.trim(), index, 'volume')}></TextField></TableCell>

                                                    {/* 操作按钮 -->添加、删除 */}
                                                    <TableCell className="caozuo_box">
                                                        <Box className="add_goods" onClick={() => { handleAddGoods() }}>{formatMsg("dig.addGoods")}</Box>
                                                        {/* 如果是购物车创建的商品不提供删除 根据id判断是否是购物车商品 */}
                                                        {tableList.length > 1 && index > 0 && !item?.id &&
                                                            <Box className="del_goods" onClick={() => { handleDelGoods(index) }}>{formatMsg("dig.delGoods")}</Box>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}

                                            <TableRow>
                                                <TableCell colSpan={2} sx={{ textAlign: "center" }}>{formatMsg("dig.CommodityQuality")}</TableCell>
                                                <TableCell colSpan={8}>
                                                    <TextFieldFOb name="goods_standard"  {...baseFieldProps} sx={{ paddingLeft: "0px" }}></TextFieldFOb>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2} sx={{ textAlign: "center" }}>{formatMsg("dig.TotalNumber")}</TableCell>
                                                <TableCell colSpan={3}>{goodsTotalNumber}</TableCell>
                                                <TableCell colSpan={2}>{formatMsg("dig.TotalPrice")}</TableCell>
                                                <TableCell colSpan={2}>${goodsTotalPrice.toFixed(2)}</TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            {/* 下滑杠 */}
                            <Box className="xiahuagang_box"></Box>
                            {/* 描述说明 */}
                            <Box className="descripution_box">
                                <Box className="des_title">{formatMsg("Specification")}</Box>
                                <Box className="des_way">
                                    <Box className="wap_title">{formatMsg("payWayDes")}</Box>
                                    <Box className="methed_way">
                                        <Box className="input_way">A、{formatMsg("information.totalPrice")}
                                            {/* <TextFields name="totalPrice" {...baseFieldProps} sx={{ width: "160px", marginLeft: "5px", }} onChange={(e) => handleValueTotalPrice(e.target.value.trim())}></TextFields>, */}
                                            <Box className="total_price_css">${(formData.totalPrice || goodsTotalPrice).toFixed(2)}</Box>
                                            <Box className="input_des_box" sx={{ marginLeft: "5px" }}>{formatMsg("information.deposit")}</Box>
                                            <TextFields name="deposit" disabled={integerFlage}  {...baseFieldProps} sx={{ width: "120px", marginLeft: "5px" }} onChange={(e) => handleValueDeposit(e.target.value)}></TextFields>%,
                                            <Box className="input_des_box" sx={{ marginLeft: "5px" }}>{formatMsg("information.Balance")}</Box>
                                            <Box className="ykuan_box">${spareMoney}</Box>
                                        </Box>

                                        <Box className="day_box">B、
                                            {/* 赛选 */}
                                            <Box className="radio_css">
                                                <RadioGroup
                                                    row
                                                    value={selectContact}
                                                    onChange={(e) => {
                                                        handleSelectOrderContract(e.target.value);
                                                    }}
                                                >
                                                    {orderMethodKeys.map((o) => (
                                                        <FormControlLabel
                                                            key={o.key}
                                                            value={o.key}
                                                            label={formatMsg({ id: o.name })}
                                                            control={<Radio size="small" sx={{ pl: 1, pr: 1, pt: 0, pb: 0 }} />}
                                                        ></FormControlLabel>
                                                    ))}
                                                </RadioGroup>
                                            </Box>
                                            <TextFields disabled={disabledFlag} name="day" {...baseFieldProps} sx={{ width: "130px" }}></TextFields>
                                            {formatMsg("daydes")}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="des_way">
                                    <Box className="wap_title">{formatMsg("huopingGuanliDes")}</Box>
                                    <Box className="methed_way_two">
                                        <Box>{formatMsg("huopingGuanliDes1")}</Box>
                                        <Box sx={{ paddingLeft: "10px" }}>{formatMsg("huopingGuanliDes2")}</Box>
                                    </Box>
                                </Box>
                                <Box className="des_way">
                                    <Box>
                                        {formatMsg("payWayDes3")}
                                    </Box>
                                </Box>
                                <Box className="des_way">
                                    <Box>
                                        {formatMsg("payWayDes4")}
                                    </Box>
                                </Box>
                                <Box className="des_way">
                                    <Box>
                                        {formatMsg("payWayDes5")}
                                    </Box>
                                </Box>
                            </Box>

                            {/* 下面签名 */}
                            {/* <Box className="btn_signature">
                                <Box className="left_box">

                                    <Box className="qianming_box">{formatMsg("signature_user")}
                                        {(userVerifyStatus == 0 || userVerifyStatus == 1) && <Box className="status_first">{formatMsg("contract.toBeConfirmed")}</Box>}
                                        {userVerifyStatus == 2 && <Box className="status_second">{formatMsg("contract.confirmed")}</Box>}
                                    </Box>
                                </Box>
                                <Box className="right_box">
                                    <Box className="qianming_box">{formatMsg("signature_merchart")}
                                        {(merchantVerifyStatus == 0 || merchantVerifyStatus == 1) && <Box className="status_first">{formatMsg("contract.toBeConfirmed")}</Box>}
                                        {merchantVerifyStatus == 2 && <Box className="status_second">{formatMsg("contract.confirmed")}</Box>}


                                    </Box>
                                </Box>
                            </Box> */}

                            {/* 下面按钮 */}
                            <Box className="btn_button">
                                <Button size="small" className="queren_btn" onClick={() => handleVerify(2)}>
                                    {formatMsg("SubmitBtn")}
                                </Button>
                                <Button size="small" className="sunbmit_btn" onClick={() => handleVerify(1)}>
                                    {formatMsg("TemporarySave")}
                                </Button>
                            </Box>
                        </Box>

                    </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", }}></DialogActions>

                
            </Dialog>
            <AddressBookDialog handleData={setCgAddress} ref={addressBook}></AddressBookDialog>  

        </React.Fragment>
    );
})

export default ContractAddressDialog
